'use client';

import { Flex, IconButton, Text } from '@company/ui/components';
import { Maximize2Icon, Minimize2Icon, XIcon } from '@company/ui/icons';
import { useLingui } from '@lingui/react';
import { usePieceOfInformationEditor } from '@providers/piece-of-information-editor-provider';
import { PIECE_OF_INFORMATION_TYPES } from '@typings/pieces-of-information';
import { TablePieceOfInformationContent } from './table';
import { PieceOfInformationDocumentEditor } from './document';
import { PieceOfInformationEmailDraftEditor } from './email-draft';

export const PieceOfInformationEditor = () => {
  const { openedPieceOfInformation, activeChangeProposalId, displayMode } =
    usePieceOfInformationEditor();
  const { _ } = useLingui();

  if (!openedPieceOfInformation) {
    return null;
  }

  if (openedPieceOfInformation.type === 'TABLE') {
    return (
      <TablePieceOfInformationContent
        table={openedPieceOfInformation.table}
        changeProposalId={activeChangeProposalId}
        header={{
          leftAddon: displayMode === 'panel' ? <HeaderAddon /> : null,
          rightAddon: displayMode !== 'panel' ? <HeaderAddon /> : null
        }}
      />
    );
  }

  if (openedPieceOfInformation.type === 'DOCUMENT') {
    return (
      <PieceOfInformationDocumentEditor
        document={openedPieceOfInformation.document}
        header={{
          leftAddon: displayMode === 'panel' ? <HeaderAddon /> : null,
          rightAddon: displayMode !== 'panel' ? <HeaderAddon /> : null
        }}
      />
    );
  }

  if (openedPieceOfInformation.type === 'EMAIL_DRAFT') {
    return (
      <PieceOfInformationEmailDraftEditor
        emailDraft={openedPieceOfInformation.emailDraft}
        header={{
          leftAddon: displayMode === 'panel' ? <HeaderAddon /> : null,
          rightAddon: displayMode !== 'panel' ? <HeaderAddon /> : null
        }}
      />
    );
  }

  return (
    <Text px={4}>
      {PIECE_OF_INFORMATION_TYPES[openedPieceOfInformation.type].getLabel(_)} cannot be displayed
    </Text>
  );
};

const HeaderAddon = () => {
  const {
    displayMode,
    onPieceOfInformationClose,
    onPieceOfInformationMaximize,
    onPieceOfInformationMinimize
  } = usePieceOfInformationEditor();

  const closeButton = (
    <IconButton size={'xs'} onClick={onPieceOfInformationClose} variant={'ghost'}>
      <XIcon boxSize={4} />
    </IconButton>
  );

  const maximizeButton = (
    <IconButton
      size={'xs'}
      onClick={
        displayMode === 'fullscreen' ? onPieceOfInformationMinimize : onPieceOfInformationMaximize
      }
      variant={'ghost'}
    >
      {displayMode === 'fullscreen' ? (
        <Minimize2Icon boxSize={3.5} />
      ) : (
        <Maximize2Icon boxSize={3.5} />
      )}
    </IconButton>
  );

  return (
    <Flex
      align={'center'}
      gap={1.5}
      ml={displayMode === 'panel' ? -1.5 : undefined}
      mr={displayMode !== 'panel' ? -1.5 : undefined}
    >
      {displayMode === 'panel' ? (
        <>
          {closeButton} {maximizeButton}
        </>
      ) : (
        <>
          {maximizeButton} {closeButton}
        </>
      )}
    </Flex>
  );
};
