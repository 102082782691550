'use client';

import { removeDuplicates } from '@company/common/lib';
import { createZustandContext } from '@stores/create';
import { SearchableTableDto } from '@typings/table';
import MiniSearch, { SearchResult } from 'minisearch';
import { create } from 'zustand';

interface InitialState {
  tables: SearchableTableDto[];
}

type TableQueryFilters = {
  toExcludeIds: string[];
  toIncludeIds?: string[];
};

type SearchableItem = {
  id: string;
  primaryFieldValue: string;
  rowIds: string[];
};

const fieldsToSearch = ['primaryFieldValue'];

const rebuildEngines = (tables: SearchableTableDto[]) => {
  const tableSearchEngines = tables.reduce(
    (acc, table) => {
      const miniSearch = new MiniSearch<SearchableItem>({
        fields: fieldsToSearch
      });

      miniSearch.addAll(table.searchableItems);
      acc[table.id] = miniSearch;
      return acc;
    },
    {} as Record<string, MiniSearch<SearchableItem>>
  );

  return tableSearchEngines;
};

interface TableQueryStore {
  tables: SearchableTableDto[];
  tableSearchEngines: Record<string, MiniSearch<SearchableItem>>;
  updateTables: (tables: SearchableTableDto[]) => void;
  queryTableRows: (params: {
    tableId: string;
    searchQuery: string;
    filters: TableQueryFilters;
    options?: {
      initialRowIds?: string[];
    };
    limit: number;
  }) => {
    items: SearchableItem[];
  };
}

export const [TableQueryStoreProvider, useTableQueryStore] = createZustandContext<
  InitialState,
  TableQueryStore
>(initial =>
  create<TableQueryStore>((set, get) => {
    const tableSearchEngines = rebuildEngines(initial.tables);

    return {
      tables: initial.tables,
      tableSearchEngines,
      updateTables: newTables => {
        const tableSearchEngines = rebuildEngines(newTables);
        set(state => ({ ...state, tableSearchEngines, tables: newTables }));
      },
      queryTableRows: ({ tableId, searchQuery, filters, options, limit }) => {
        const { tables, tableSearchEngines } = get();

        if (searchQuery.length < 1) {
          const table = tables.find(t => t.id === tableId);

          if (!table) {
            return { items: [] };
          }
          const filteredRows = table.searchableItems.filter(filterRows(filters));
          return {
            items: removeDuplicates(
              [
                ...filteredRows.filter(row =>
                  options?.initialRowIds?.some(id => row.rowIds.includes(id))
                ),
                ...filteredRows
              ],
              item => item.id
            ).slice(0, limit)
          };
        }

        const miniSearch = tableSearchEngines[tableId]!;

        const searchResults = miniSearch.search(searchQuery, {
          fields: fieldsToSearch,
          prefix: true,
          fuzzy: 0.2,
          combineWith: 'AND'
        });
        const idToSearchResult = searchResults.reduce(
          (acc, result) => {
            acc[result.id] = result;
            return acc;
          },
          {} as Record<string, SearchResult>
        );

        const table = tables.find(t => t.id === tableId);

        if (!table) {
          return { items: [] };
        }

        const filteredRows = table.searchableItems
          .filter(item => idToSearchResult[item.id] !== undefined)
          .filter(filterRows(filters))
          .slice(0, limit)
          .sort(
            (a, b) =>
              searchResults.map(result => result.id).indexOf(a.id) -
              searchResults.map(result => result.id).indexOf(b.id)
          );

        return { items: filteredRows };
      }
    };
  })
);

const filterRows = (filters: TableQueryFilters) => {
  return (item: { rowIds: string[] }) => {
    if (filters.toIncludeIds && !filters.toIncludeIds.some(id => item.rowIds.includes(id))) {
      return false;
    }

    if (filters.toExcludeIds.some(id => item.rowIds.includes(id))) {
      return false;
    }

    return true;
  };
};
