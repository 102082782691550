'use client';

import { htmlToFormattedString, rowValueToString } from '@company/common/lib';
import { TableRowDataType } from '@company/common/types';
import { Box, Card, Flex, Stack, Text } from '@company/ui/components';
import { SquareEqualIcon, TableIcon } from '@company/ui/icons';
import { TableRowValueRenderer } from '@components/table/common/table-row-value-renderer';
import { ILinkedRowRecommendation, LinkedRowTableForComparison } from '@typings/table';
import { diffWords } from 'diff';
import React from 'react';

export const LinkedRowComparison = ({
  recommendation,
  linkedTable,
  currentTable,
  showDiff
}: {
  recommendation: ILinkedRowRecommendation;
  linkedTable: LinkedRowTableForComparison;
  currentTable: LinkedRowTableForComparison;
  showDiff: boolean;
}) => {
  const linkedTableFieldIdToNameMap: Record<string, string> = React.useMemo(() => {
    return linkedTable.fields.reduce(
      (acc, field) => {
        acc[field.id] = field.name;
        return acc;
      },
      {} as Record<string, string>
    );
  }, [linkedTable]);
  const currentTableFieldIdToNameMap: Record<string, string> = React.useMemo(() => {
    return currentTable.fields.reduce(
      (acc, field) => {
        acc[field.id] = field.name;
        return acc;
      },
      {} as Record<string, string>
    );
  }, [currentTable]);

  return (
    <Stack pt={1} pb={4} gap={6}>
      {recommendation.fieldComparisons.map(({ currentTableFieldId, linkedTableFieldId }, index) => (
        <Box key={index}>
          <Flex w={'100%'} gap={2} align={'flex-start'}>
            <Box w={'100%'} h={'100%'}>
              <CellValueCard
                columnName={linkedTableFieldIdToNameMap[linkedTableFieldId]!}
                content={
                  showDiff ? (
                    <DiffValueRenderer
                      currentValue={recommendation.currentTableRowData[currentTableFieldId]}
                      recommendedValue={recommendation.recommendedTableRowData[linkedTableFieldId]}
                    />
                  ) : (
                    <TableRowValueRenderer
                      value={recommendation.recommendedTableRowData[linkedTableFieldId]}
                    />
                  )
                }
                headerBackgroundColor={'blue.50'}
                tableName={linkedTable.name}
              />
            </Box>
            <Box my={'auto'}>
              <SquareEqualIcon color={'gray.500'} />
            </Box>
            <Box w={'100%'}>
              <CellValueCard
                columnName={currentTableFieldIdToNameMap[currentTableFieldId]!}
                content={
                  <TableRowValueRenderer
                    value={recommendation.currentTableRowData[currentTableFieldId]}
                  />
                }
                headerBackgroundColor={'gray.50'}
                tableName={currentTable.name}
              />
            </Box>
          </Flex>
        </Box>
      ))}
    </Stack>
  );
};

const CellValueCard = ({
  columnName,
  content,
  headerBackgroundColor,
  tableName
}: {
  columnName: string;
  content: React.ReactNode;
  headerBackgroundColor: string;
  tableName: string;
}) => {
  return (
    <Card.Root bgColor={'white'} w={'100%'} h={'100%'}>
      <Card.Header
        px={4}
        py={2}
        borderBottomWidth={1}
        backgroundColor={headerBackgroundColor}
        roundedTop={'md'}
      >
        <Flex justify={'space-between'}>
          <Box>
            <Flex align={'center'}>
              <Text fontWeight={'medium'} fontSize={'sm'}>
                {columnName}
              </Text>
            </Flex>
          </Box>
          <Box fontSize={'xs'} color={'gray.600'}>
            <Flex align={'center'} gap={1}>
              <TableIcon />
              {tableName}
            </Flex>
          </Box>
        </Flex>
      </Card.Header>
      <Card.Body px={4} py={2} fontSize={'xs'}>
        {content}
      </Card.Body>
    </Card.Root>
  );
};

const DiffValueRenderer = ({
  currentValue,
  recommendedValue
}: {
  currentValue: TableRowDataType | undefined;
  recommendedValue: TableRowDataType | undefined;
}) => {
  const currentStr = htmlToFormattedString(rowValueToString(currentValue));
  const recommendedStr = htmlToFormattedString(rowValueToString(recommendedValue));

  const recommendedParagraphs = recommendedStr.split('\n\n');
  const currentParagraphs = currentStr.split('\n\n');

  return (
    <Stack gap={2}>
      {recommendedParagraphs.map((paragraph, index) => {
        const recommendedLines = paragraph.split('\n');
        const currentLines = currentParagraphs[index]?.split('\n') ?? [];

        return (
          <Box key={index}>
            {recommendedLines.map((line, index) => {
              const diff = diffWords(line, currentLines[index] ?? '', {
                ignoreWhitespace: true
              });

              return (
                <Box key={index}>
                  {diff.map((part, index) => {
                    const color = part.added ? 'green' : part.removed ? 'red' : 'inherit';
                    const textDecoration = part.removed ? 'line-through' : 'none';
                    return (
                      <Text as={'span'} key={index} style={{ color, textDecoration }}>
                        {part.value}
                      </Text>
                    );
                  })}
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Stack>
  );
};
