'use client';

import type { ProposedChange } from '@company/database/core';
import { getProposedChangesRealtimeChannelName } from '@company/supabase/realtime';
import { useRealtime } from '@hooks/use-realtime';

interface UseSubscribeToProposedChangesProps {
  changeProposalId: string | null;
  addProposedChangesToTableRows: (proposedChanges: ProposedChange[]) => void;
}

export const useSubscribeToProposedChanges = ({
  changeProposalId,
  addProposedChangesToTableRows
}: UseSubscribeToProposedChangesProps) => {
  if (!changeProposalId) {
    return;
  }

  useRealtime<ProposedChange>({
    channelName: getProposedChangesRealtimeChannelName(changeProposalId),
    subscription: {
      table: 'ProposedChange',
      schema: 'public',
      filter: `changeProposalId=eq.${changeProposalId}`
    },
    processEvent: payload => {
      if (payload.eventType === 'INSERT' || payload.eventType === 'UPDATE') {
        addProposedChangesToTableRows([payload.new]);
      }
    }
  });
};
