import { FieldDto } from '@company/common/types';
import React, { useCallback } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useTableStore } from '../stores/table-store';

export function useField() {
  const { fields } = useTableStore(
    useShallow(state => ({
      fields: state.table.fields
    }))
  );

  const fieldIdToField = React.useMemo(() => {
    return fields.reduce(
      (acc, field) => {
        acc[field.id] = field;
        return acc;
      },
      {} as Record<string, FieldDto>
    );
  }, [fields]);

  const getFieldById = useCallback(
    (fieldId: string) => {
      const field = fieldIdToField[fieldId];
      if (!field) {
        throw new Error(`Field with id ${fieldId} not found`);
      }
      return field;
    },
    [fieldIdToField]
  );

  const getPrimaryField = useCallback(() => {
    const field = fields.find(field => field.isPrimary);
    if (!field) {
      throw new Error('Primary field not found');
    }
    return field;
  }, [fields]);

  return {
    getFieldById,
    getPrimaryField
  };
}
