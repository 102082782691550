'use client';

import { TableRowLinkedRowType } from '@company/common/types';
import { Box, CloseButton, Dialog, Heading, Text } from '@company/ui/components';
import { useField } from '@components/table/hooks';
import { useTableStore } from '@components/table/stores/table-store';
import { Trans } from '@lingui/react/macro';
import { useShallow } from 'zustand/react/shallow';
import { RowSwitcher } from '../common/dialog-row-switcher';
import { OpenedLinkedRowDialog } from '../types';
import { LinkedRowBody } from './content';
import { trpc } from '@server/trpc';
import { Project } from './types';

interface LinkedRowDialogProps {
  openedLinkedRowDialog: OpenedLinkedRowDialog | null;
  onClose: () => void;
}

export const LinkedRowDialog = ({ openedLinkedRowDialog, onClose }: LinkedRowDialogProps) => {
  const { data: projects } = trpc.project.getAll.useQuery();

  return (
    <Dialog.Root
      open={!!openedLinkedRowDialog}
      onOpenChange={({ open }) => {
        if (!open) {
          onClose();
        }
      }}
      size={'cover'}
      scrollBehavior={'inside'}
      placement={'center'}
    >
      <Dialog.Backdrop />
      <Dialog.Positioner>
        <Dialog.Content my={0} maxH={'100%'}>
          <Dialog.Header display={'flex'} gap={1} alignItems={'center'} pb={0} pt={2}>
            {openedLinkedRowDialog && (
              <RowSwitcher
                type={'linked-row'}
                rowId={openedLinkedRowDialog.rowId}
                fieldId={openedLinkedRowDialog.fieldId}
              />
            )}
            <Box pl={2}>
              <Heading fontSize={'lg'} fontWeight={'semibold'}>
                {openedLinkedRowDialog?.title}
              </Heading>
              <Text fontSize={'sm'} color={'GrayText'}>
                <Trans>The following rows where recommended</Trans>
              </Text>
            </Box>
            <Dialog.CloseTrigger asChild>
              <CloseButton onClick={onClose} size={'sm'} ml={'auto'} />
            </Dialog.CloseTrigger>
          </Dialog.Header>
          <Dialog.Body pl={0}>
            {openedLinkedRowDialog && (
              <LinkedRowBodyWrapper
                openedLinkedRowDialog={openedLinkedRowDialog}
                projects={projects ?? []}
              />
            )}
          </Dialog.Body>
        </Dialog.Content>
      </Dialog.Positioner>
    </Dialog.Root>
  );
};

const LinkedRowBodyWrapper = ({
  openedLinkedRowDialog,
  projects
}: {
  openedLinkedRowDialog: OpenedLinkedRowDialog;
  projects: Project[];
}) => {
  const { updateRows, getValue } = useTableStore(
    useShallow(state => ({
      updateRows: state.updateRows,
      getValue: state.getValue
    }))
  );
  const { getFieldById } = useField();

  const linkedRowValue = getValue(
    openedLinkedRowDialog.rowId,
    openedLinkedRowDialog.fieldId
  ) as TableRowLinkedRowType;

  return (
    <LinkedRowBody
      fieldId={openedLinkedRowDialog.fieldId}
      rowId={openedLinkedRowDialog.rowId}
      linkedRowData={linkedRowValue}
      linkedTableId={getFieldById(openedLinkedRowDialog.fieldId)?.linkedRowConfig!.tableId}
      updateLinkedRows={linkedRows =>
        void updateRows([
          {
            id: openedLinkedRowDialog.rowId,
            [openedLinkedRowDialog.fieldId]: linkedRows
          }
        ])
      }
      type={openedLinkedRowDialog.linkedRowConfigType}
      projects={projects}
    />
  );
};
