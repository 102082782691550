'use client';

import { convertToTableRowDto } from '@company/common/lib';
import { TableRowLinkedRowType } from '@company/common/types';
import {
  Box,
  Flex,
  PopoverBody,
  PopoverContent,
  PopoverRoot,
  Search
} from '@company/ui/components';
import { addLinkedRowRecommendationAction } from '@components/table/actions/linked-row';
import { useLinkedRow } from '@components/table/providers/linked-row-provider';
import { useTableQueryStore } from '@components/table/stores/table-query-store';
import { useTableStore } from '@components/table/stores/table-store';
import { ViewField } from '@components/table/types';
import { msg } from '@lingui/core/macro';
import { useLingui } from '@lingui/react';
import { ILinkedRowRecommendation, TableRowSearchItemDto } from '@typings/table';
import React from 'react';
import { typeidUnboxed } from 'typeid-js';
import { useShallow } from 'zustand/react/shallow';
import { LinkedRowTag } from '../linked-row/tag';

interface LinkedRowSearchPopoverProps {
  ref: React.RefObject<HTMLButtonElement | HTMLDivElement | null>;
  rowId: string;
  field: ViewField;
  cellValue: TableRowLinkedRowType;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  returnFocus: () => void;
  isPortalled?: boolean;
  onSelect?: ({
    recommendation,
    item
  }: {
    recommendation: ILinkedRowRecommendation;
    item: TableRowSearchItemDto;
  }) => void;
}

export const LinkedRowSearchPopover = ({
  ref,
  rowId,
  field,
  cellValue,
  isOpen,
  setIsOpen,
  returnFocus,
  isPortalled = true,
  onSelect: onSelectCallback
}: LinkedRowSearchPopoverProps) => {
  const { _ } = useLingui();
  const { updateRows, getRowById } = useTableStore(
    useShallow(state => ({
      updateRows: state.updateRows,
      getRowById: state.getRowById
    }))
  );
  const table = useTableStore(
    useShallow(state => ({
      id: state.table.id
    }))
  );
  const { getLinkedRowRecommendationProcess, getLinkedRowTable } = useLinkedRow();
  const { queryTableRows } = useTableQueryStore();

  const [inputValue, setInputValue] = React.useState('');

  const linkedRowRecommendationProcess = React.useMemo(
    () => getLinkedRowRecommendationProcess({ fieldId: field.id, rowId }),
    [field.id, rowId]
  );

  const linkedTable = React.useMemo(
    () => getLinkedRowTable({ tableId: field.linkedRowConfig!.tableId }),
    [field.linkedRowConfig!.tableId]
  );

  const hasRecommendations = (linkedRowRecommendationProcess?.recommendations.length ?? 0) > 0;

  const items = React.useMemo(() => {
    if (inputValue.length < 1 && hasRecommendations) {
      const result = queryTableRows({
        tableId: field.linkedRowConfig!.tableId,
        searchQuery: '',
        filters: {
          toExcludeIds: cellValue.linkedRows.map(row => row.linkedRowId)
        },
        options: {
          initialRowIds:
            linkedRowRecommendationProcess?.recommendations.map(rec => rec.recommendedRowId) ?? []
        },
        limit: 16
      });
      return result.items;
    }
    const result = queryTableRows({
      tableId: field.linkedRowConfig!.tableId,
      searchQuery: inputValue,
      filters: { toExcludeIds: cellValue.linkedRows.map(row => row.linkedRowId) },
      limit: 32
    });

    return result.items;
  }, [inputValue, cellValue.linkedRows, linkedRowRecommendationProcess, queryTableRows]);

  const onSelect = async (item: TableRowSearchItemDto) => {
    if (!item) {
      return;
    }
    const isSingleLinkedRow = field.linkedRowConfig!.type === 'SINGLE_ROW';
    const linkedRowId = item.rowIds[0]!;
    const value: TableRowLinkedRowType = {
      linkedRows: isSingleLinkedRow
        ? [
            {
              linkedRowId,
              primaryFieldValue: item.primaryFieldValue,
              recommendationScore: getRecommendationScore(linkedRowId)
            }
          ]
        : [
            ...cellValue.linkedRows,
            {
              linkedRowId,
              primaryFieldValue: item.primaryFieldValue,
              recommendationScore: getRecommendationScore(linkedRowId)
            }
          ].filter(
            (_, index, self) => self.findIndex(t => t.linkedRowId === _?.linkedRowId) === index
          ),
      recommendationStatus: cellValue.recommendationStatus
    };
    void updateRows([
      {
        id: rowId,
        [field.id]: value
      }
    ]);

    const recommendation: ILinkedRowRecommendation = {
      id: typeidUnboxed('linkedrowrec'),
      currentTableRowData: convertToTableRowDto(getRowById(rowId)),
      recommendedTableRowData: convertToTableRowDto(item),
      score: null,
      isFromAiMemory: false,
      fieldComparisons:
        field.linkedRowConfig!.aiConfig?.fieldMappings.map(mapping => ({
          currentTableFieldId: field.id,
          linkedTableFieldId: mapping.linkedTableFieldId,
          score: 0,
          scoreWeight: 0
        })) ?? [],
      recommendedRowId: linkedRowId,
      isHidden: false
    };

    setIsOpen(false);
    onSelectCallback?.({ recommendation, item });
    returnFocus();

    if (linkedRowRecommendationProcess) {
      await addLinkedRowRecommendationAction({
        id: recommendation.id,
        fieldId: field.id,
        rowId,
        tableId: table.id,
        recommendationProcessId: linkedRowRecommendationProcess!.id,
        recommendedRowId: linkedRowId
      });
    }
  };

  const getRecommendationScore = (linkedRowId: string) => {
    return (
      linkedRowRecommendationProcess?.recommendations.find(
        rec => rec.recommendedRowId === linkedRowId
      )?.score ?? null
    );
  };

  React.useEffect(() => {
    setInputValue('');
  }, [isOpen]);

  const renderItem = React.useCallback(
    (item: TableRowSearchItemDto) => {
      return (
        <Flex w={'full'} h={'full'} align={'center'} justify={'space-between'} gap={2} minH={6}>
          <Box color={'gray.800'} w={'full'} lineClamp={1} fontSize={'13px'}>
            {item.primaryFieldValue}
          </Box>
          <Box>
            <LinkedRowTag score={getRecommendationScore(item.rowIds[0]!)} />
          </Box>
        </Flex>
      );
    },
    [field, linkedTable?.fields, getRecommendationScore]
  );

  return (
    <PopoverRoot
      open={isOpen}
      onFocusOutside={() => setIsOpen(false)}
      onInteractOutside={() => setIsOpen(false)}
      onEscapeKeyDown={() => {
        setIsOpen(false);
        returnFocus();
      }}
      positioning={{
        getAnchorRect: () => {
          return ref.current?.getBoundingClientRect() ?? null;
        },
        placement: 'bottom-end'
      }}
    >
      <PopoverContent minW={'520px'} borderWidth={1} shadow={'xl'} portalled={isPortalled}>
        <PopoverBody p={0}>
          <Search
            isLoading={false}
            items={items}
            selectedItems={items.filter(item =>
              cellValue.linkedRows.map(r => r.linkedRowId).includes(item.rowIds[0]!)
            )}
            inputValue={inputValue}
            setInputValue={setInputValue}
            placeholder={_(msg`Search`)}
            renderItem={renderItem}
            onSelect={onSelect}
            texts={{
              noResults: _(msg`No results found for`),
              loading: _(msg`Loading...`)
            }}
            inputProps={{
              border: 'none',
              roundedBottom: 'none',
              focusRing: 'none',
              autoFocus: true
            }}
            contentProps={{
              shadow: 'none',
              borderTopWidth: 1,
              roundedTop: 'none',
              minH: '200px',
              maxH: '380px'
            }}
          />
        </PopoverBody>
      </PopoverContent>
    </PopoverRoot>
  );
};
