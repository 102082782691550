'use client';

import { Box, IconButton, Tooltip } from '@company/ui/components';
import { ChevronDownIcon, ChevronUpIcon } from '@company/ui/icons';
import { useTableStore } from '@components/table/stores/table-store';
import { Trans } from '@lingui/react/macro';
import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useGrid } from '../provider';

export const RowSwitcher = ({
  type,
  rowId,
  fieldId
}: {
  type: 'child-table' | 'linked-row';
  rowId: string;
  fieldId?: string;
}) => {
  const { onOpenChildTable, onOpenLinkedRowDialog } = useGrid();
  const table = useTableStore(
    useShallow(state => ({
      childTableId: state.table.childTableId,
      rows: state.table.rows
    }))
  );
  const getPreviousLeafRow = React.useCallback(
    (rowId: string) => {
      const index = table.rows.findIndex(row => row.id === rowId);
      const prevRow = table.rows[index - 1] ?? null;
      if (prevRow && !prevRow.isLeafRow) {
        return getPreviousLeafRow(prevRow.id);
      }
      return prevRow;
    },
    [table.rows]
  );

  const getNextLeafRow = React.useCallback(
    (rowId: string) => {
      const index = table.rows.findIndex(row => row.id === rowId);
      const nextRow = table.rows[index + 1] ?? null;
      if (nextRow && !nextRow.isLeafRow) {
        return getNextLeafRow(nextRow.id);
      }
      return nextRow;
    },
    [table.rows]
  );

  const previousRow = React.useMemo(() => getPreviousLeafRow(rowId), [rowId, getPreviousLeafRow]);
  const nextRow = React.useMemo(() => getNextLeafRow(rowId), [rowId, getNextLeafRow]);

  const onPreviousRow = () => {
    if (!previousRow) return;
    if (type === 'child-table' && table.childTableId) {
      onOpenChildTable({
        childTableId: table.childTableId!,
        parentTableRowId: previousRow.id,
        primaryFieldValue: previousRow.primaryFieldValue
      });
    } else if (type === 'linked-row') {
      onOpenLinkedRowDialog({ rowId: previousRow.id, fieldId: fieldId! });
    }
  };

  const onNextRow = () => {
    if (!nextRow) return;
    if (type === 'child-table' && table.childTableId) {
      onOpenChildTable({
        childTableId: table.childTableId!,
        parentTableRowId: nextRow.id,
        primaryFieldValue: nextRow.primaryFieldValue
      });
    } else if (type === 'linked-row') {
      onOpenLinkedRowDialog({ rowId: nextRow.id, fieldId: fieldId! });
    }
  };

  return (
    <Box ml={-2}>
      <Tooltip content={<Trans>Previous Row</Trans>} positioning={{ placement: 'top' }}>
        <IconButton
          variant={'ghost'}
          size={'xs'}
          display={'block'}
          onClick={onPreviousRow}
          disabled={!previousRow}
        >
          <ChevronUpIcon />
        </IconButton>
      </Tooltip>
      <Tooltip content={<Trans>Next Row</Trans>}>
        <IconButton variant={'ghost'} size={'xs'} onClick={onNextRow} disabled={!nextRow}>
          <ChevronDownIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
