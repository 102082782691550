'use client';

import { AgGridReact } from '@ag-grid-community/react';
import { Box, Button, Center, CloseButton, Flex, Separator } from '@company/ui/components';
import { useTableStore } from '@components/table/stores/table-store';
import { Trans } from '@lingui/react/macro';
import { TableRowDtoUi } from '@typings/table';
import React from 'react';
import { useShallow } from 'zustand/react/shallow';

interface GridActionBarProps {
  gridApiRef: React.RefObject<AgGridReact<TableRowDtoUi>> | null;
}

export const GridActionBar: React.FC<GridActionBarProps> = ({ gridApiRef }) => {
  const { deleteRows, approveProposedChanges, declineProposedChanges } = useTableStore(
    useShallow(state => ({
      deleteRows: state.deleteRows,
      approveProposedChanges: state.approveProposedChanges,
      declineProposedChanges: state.declineProposedChanges
    }))
  );

  const [selectedRows, setSelectedRows] = React.useState<TableRowDtoUi[]>([]);
  const [isApprovingChangeProposal, setIsApprovingChangeProposal] = React.useState(false);
  const [isDecliningChangeProposal, setIsDecliningChangeProposal] = React.useState(false);

  React.useEffect(() => {
    if (!gridApiRef?.current?.api) return;
    const onSelectionChanged = () => {
      setSelectedRows(gridApiRef.current?.api.getSelectedNodes().map(node => node.data!));
    };

    gridApiRef.current?.api.addEventListener('selectionChanged', onSelectionChanged);

    return () => {
      gridApiRef.current?.api?.removeEventListener('selectionChanged', onSelectionChanged);
    };
  }, [gridApiRef?.current?.api, setSelectedRows]);

  const onDeleteRows = () => {
    if (!gridApiRef?.current?.api) return;
    const selectedRows = gridApiRef.current?.api
      .getSelectedNodes()
      .filter(node => node.data !== undefined);

    gridApiRef.current.api.applyTransaction({
      remove: selectedRows.map(node => node.data!)
    });
    deleteRows(selectedRows.map(node => node.data).map(data => ({ id: data!.id })));
    gridApiRef.current?.api.deselectAll();
  };

  const onDeselectAll = () => {
    if (!gridApiRef?.current?.api) return;
    gridApiRef.current?.api.deselectAll();
    gridApiRef.current?.api.refreshHeader();
    gridApiRef.current?.api.refreshCells();
    setSelectedRows([]);
  };

  const onApproveChangeProposal = async () => {
    setIsApprovingChangeProposal(true);
    await approveProposedChanges(selectedRows.map(row => row.id));
    setIsApprovingChangeProposal(false);
  };

  const onDeclineChangeProposal = async () => {
    setIsDecliningChangeProposal(true);
    await declineProposedChanges(selectedRows.map(row => row.id));
    setIsDecliningChangeProposal(false);
  };

  if (selectedRows.length === 0) {
    return null;
  }

  const hasProposedChanges = selectedRows.some(row => row.proposedChange !== null);

  return (
    <Box position={'absolute'} bottom={12} left={'50%'} transform={'translateX(-50%)'}>
      <Flex
        shadow={'md'}
        rounded={'md'}
        bgColor={'white'}
        borderWidth={1}
        p={3}
        alignItems={'center'}
        gap={3}
      >
        <Flex alignItems={'center'} gap={1.5}>
          <Center bgColor={'blue.500'} color={'white'} px={1.5} py={'2px'} rounded={'md'}>
            {selectedRows.length}
          </Center>{' '}
          <Trans>rows selected</Trans>
        </Flex>
        {hasProposedChanges && (
          <>
            <Button
              loading={isApprovingChangeProposal}
              loadingText="Approving"
              disabled={isDecliningChangeProposal}
              onClick={onApproveChangeProposal}
              colorPalette={'green'}
              size={'xs'}
              variant={'surface'}
            >
              <Trans>Approve changes</Trans>
            </Button>
            <Button
              loading={isDecliningChangeProposal}
              loadingText="Declining"
              disabled={isApprovingChangeProposal}
              onClick={onDeclineChangeProposal}
              colorPalette={'red'}
              size={'xs'}
              variant={'surface'}
            >
              <Trans>Decline changes</Trans>
            </Button>
            <Separator orientation="vertical" h={'32px'} />
          </>
        )}
        <Button variant={'surface'} colorPalette={'red'} size={'xs'} onClick={onDeleteRows} mr={-2}>
          <Trans>Delete Rows</Trans>
        </Button>
        <CloseButton size="sm" onClick={onDeselectAll} />
      </Flex>
    </Box>
  );
};
