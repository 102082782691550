import { formatDate } from '@company/common/lib';
import { Text } from '@company/ui/components';
import { useAuthUser } from '@providers/auth-user-provider';
import { CellRendererProps } from './types';

export const DateTimeCell: React.FC<CellRendererProps> = props => {
  const { localeCode } = useAuthUser();
  const value = props.row[props.field.id] as Date | null | undefined;
  return <Text>{value ? formatDate(value, localeCode, 'dd. MMM yyyy HH:mm') : ''}</Text>;
};
