import { Checkbox } from '@company/ui/components';
import React from 'react';
import { CellRendererProps } from './types';
import { useShallow } from 'zustand/react/shallow';
import { useTableStore } from '@components/table/stores/table-store';

export const CheckboxCell: React.FC<CellRendererProps> = props => {
  const value = props.row[props.field.id] as boolean | null | undefined;
  const { updateRows } = useTableStore(
    useShallow(state => ({
      updateRows: state.updateRows
    }))
  );

  const [isChecked, setIsChecked] = React.useState(value ?? false);

  React.useEffect(() => {
    setIsChecked(value ?? false);
  }, [value]);

  const handleChange = (checked: boolean) => {
    setIsChecked(checked);
    void updateRows([
      {
        id: props.row.id,
        [props.field.id]: checked
      }
    ]);
  };

  return (
    <Checkbox
      checked={isChecked}
      size={'sm'}
      onCheckedChange={({ checked }) => handleChange(!!checked)}
    />
  );
};
