'use client';

import { Center, Checkbox, Flex, IconButton, Text } from '@company/ui/components';
import { Maximize2Icon } from '@company/ui/icons';
import { useTableStore } from '@components/table/stores/table-store';
import { TableRowDtoUi } from '@typings/table';
import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useGrid } from '../provider';

interface ActionCellProps {
  row: TableRowDtoUi;
  rowIndex: number | null;
  isSelected: boolean;
  setSelected: (isSelected: boolean) => void;
}

export const ActionCell: React.FC<ActionCellProps> = React.memo(
  ({ row, rowIndex, isSelected, setSelected }) => {
    const { activeView, childTableId } = useTableStore(
      useShallow(state => ({
        activeView: state.table.activeView,
        childTableId: state.table.childTableId
      }))
    );
    const { onOpenChildTable } = useGrid();

    const onCheckboxClick = React.useCallback(() => {
      if (!activeView.config.canDeleteRows) {
        return;
      }

      setSelected(!isSelected);
    }, [isSelected, setSelected, activeView.config.canDeleteRows]);

    const onMaximizeClick = React.useCallback(() => {
      if (!childTableId) {
        return;
      }

      onOpenChildTable({
        childTableId,
        parentTableRowId: row.id,
        primaryFieldValue: row.primaryFieldValue
      });
    }, [onOpenChildTable, childTableId, row.id, row.primaryFieldValue]);

    return (
      <Flex gap={2} align={'center'} h={'full'}>
        <Center
          minW={8}
          h={'full'}
          _hover={activeView.config.canDeleteRows ? { bg: 'bg.muted' } : undefined}
          ml={`-10px`}
          onClick={onCheckboxClick}
        >
          {rowIndex !== null && (
            <Text
              className={activeView.config.canDeleteRows ? 'hide-on-row-hover' : undefined}
              display={isSelected ? 'none' : 'block'}
              fontSize={'xs'}
              color={'GrayText'}
            >
              {rowIndex + 1}
            </Text>
          )}
          <Checkbox
            size={'xs'}
            variant={'solid'}
            colorPalette={'blue'}
            className={
              activeView.config.canDeleteRows && !isSelected ? 'display-on-row-hover' : undefined
            }
            display={isSelected ? 'block' : 'none'}
            checked={isSelected}
          />
        </Center>
        {row.isLeafRow && childTableId !== null && (
          <Center className="display-on-row-hover">
            <IconButton
              size={'xs'}
              variant={'ghost'}
              h={6}
              w={6}
              minW={6}
              onClick={onMaximizeClick}
              colorPalette={'primary'}
            >
              <Maximize2Icon fontSize={'sm'} />
            </IconButton>
          </Center>
        )}
      </Flex>
    );
  }
);
