'use client';

import { convertDtoToUpsertTableRow } from '@company/common/lib';
import {
  EditFieldInTable,
  TableRowDataType,
  TableRowLinkedRowType,
  UpsertTableRow,
  ViewState
} from '@company/common/types';
import { toaster } from '@company/ui/components';
import { createZustandContext } from '@stores/create';
import { TableDtoUi, TableRowDtoUi } from '@typings/table';
import { create } from 'zustand';
import {
  approveChangeProposalAction,
  declineChangeProposalAction,
  modifyTableRowsAction,
  updateTableViewStateAction
} from '../actions';
import { editFieldAction } from '../actions/field';
import { DeleteTableRow, UpdateTableRow } from '../types';
interface InitialState {
  table: TableDtoUi;
  fetchTable: () => Promise<TableDtoUi | null>;
}

interface TableStore {
  table: TableDtoUi;
  isViewingChangeProposalDiff: boolean;
  rowIdToRow: Record<string, TableRowDtoUi>;
  updateTable: (table: TableDtoUi) => void;
  refetchTable: () => Promise<void>;
  addRow: (
    args: { row: UpsertTableRow } & ({ referenceRowId: string; position: 'above' | 'below' } | {})
  ) => void;
  updateRows: (rows: UpdateTableRow[], options?: { shouldSaveChanges?: boolean }) => void;
  deleteRows: (rows: DeleteTableRow[]) => void;
  clearRowValues: (cells: { rowId: string; fieldId: string }[]) => void;
  getRowById: (rowId: string) => TableRowDtoUi;
  getValue: (rowId: string, fieldId: string) => TableRowDataType;
  updateField: (field: EditFieldInTable) => void;
  viewChangeProposalDiff: () => void;
  hideChangeProposalDiff: () => void;
  updateActiveViewState: (
    newState: Partial<ViewState>,
    options?: { shouldSaveToDb?: boolean }
  ) => void;
  approveProposedChanges: (rowIds: string[]) => Promise<void>;
  declineProposedChanges: (rowIds: string[]) => Promise<void>;
}

export const [TableStoreProvider, useTableStore] = createZustandContext<InitialState, TableStore>(
  initial =>
    create<TableStore>((set, get) => ({
      table: initial.table,
      rowIdToRow: initial.table.rows.reduce(
        (acc, row) => {
          acc[row.id] = row;
          return acc;
        },
        {} as Record<string, TableRowDtoUi>
      ),
      isViewingChangeProposalDiff: false,
      updateTable: (table: TableDtoUi) => {
        set(state => ({
          ...state,
          rowIdToRow: table.rows.reduce(
            (acc, row) => {
              acc[row.id] = row;
              return acc;
            },
            {} as Record<string, TableRowDtoUi>
          ),
          table
        }));
      },
      refetchTable: async () => {
        const table = await initial.fetchTable();
        if (table) {
          set(state => ({
            ...state,
            table,
            rowIdToRow: table.rows.reduce(
              (acc, row) => {
                acc[row.id] = row;
                return acc;
              },
              {} as Record<string, TableRowDtoUi>
            )
          }));
        }
      },
      addRow: ({ row, ...args }) => {
        const tableRows = get().table.rows;
        const referenceRowIndex = tableRows.findIndex(
          r => r.id === ('referenceRowId' in args && args.referenceRowId)
        );
        const referenceRow = tableRows[referenceRowIndex];

        // Calculate the new order value
        let order: number = 1;

        if (referenceRowIndex === -1) {
          // If reference row not found, append to end
          const lastRow = tableRows[tableRows.length - 1];
          order = lastRow ? lastRow.order + 1 : 1;
        } else if ('position' in args) {
          const prevRow =
            args.position === 'above'
              ? tableRows[referenceRowIndex - 1]
              : tableRows[referenceRowIndex];
          const nextRow =
            args.position === 'above'
              ? tableRows[referenceRowIndex]
              : tableRows[referenceRowIndex + 1];

          if (prevRow && nextRow) {
            order = (prevRow.order + nextRow.order) / 2;
          } else if (prevRow) {
            order = tableRows.reduce((max, row) => Math.max(max, row.order), 0) + 1;
          } else if (nextRow) {
            order = 0;
          } else {
            order = 0;
          }
        }

        const rowWithOrder: TableRowDtoUi = {
          ...row,
          order,
          primaryFieldValue: null,
          proposedChange: null,
          parentRowId: referenceRow?.parentRowId ?? null,
          parentTableRowId: referenceRow?.parentTableRowId ?? null,
          projectId: referenceRow?.projectId ?? null,
          isLeafRow: referenceRow?.isLeafRow ?? true,
          parentRowIdPath: [...(referenceRow?.parentRowIdPath?.slice(0, -1) ?? []), row.id],
          status: 'ACTIVE'
        };

        const newRows = [...tableRows, rowWithOrder].sort((a, b) => a.order - b.order);

        set(state => {
          return {
            ...state,
            table: { ...state.table, rows: newRows },
            rowIdToRow: { ...state.rowIdToRow, [rowWithOrder.id]: rowWithOrder }
          };
        });

        void modifyTableRowsAction({
          tableId: get().table.id,
          toUpsertRows: [convertDtoToUpsertTableRow({ ...row, order })],
          toDeleteRows: []
        });
      },
      updateRows: (updatedRows, options) => {
        set(state => {
          const newRows = state.table.rows.map(row => {
            const update = updatedRows.find(u => u.id === row.id);
            return update ? { ...row, ...update } : row;
          });
          const newTable = { ...state.table, rows: newRows };
          return {
            ...state,
            table: newTable,
            rowIdToRow: newRows.reduce(
              (acc, row) => {
                acc[row.id] = row;
                return acc;
              },
              {} as Record<string, TableRowDtoUi>
            )
          };
        });
        if (options?.shouldSaveChanges === undefined || options?.shouldSaveChanges) {
          void modifyTableRowsAction({
            tableId: get().table.id,
            toUpsertRows: updatedRows.map(convertDtoToUpsertTableRow),
            toDeleteRows: []
          });
        }
      },
      deleteRows: rowsToDelete => {
        set(state => {
          const idsToDelete = new Set(rowsToDelete.map(r => r.id));
          const newRows = state.table.rows.filter(row => !idsToDelete.has(row.id));
          const newTable = { ...state.table, rows: newRows };

          void modifyTableRowsAction({
            tableId: get().table.id,
            toUpsertRows: [],
            toDeleteRows: rowsToDelete
          });

          return {
            table: newTable,
            rows: newRows,
            rowIdToRow: newRows.reduce(
              (acc, row) => {
                acc[row.id] = row;
                return acc;
              },
              {} as Record<string, TableRowDtoUi>
            )
          };
        });
      },
      // Improved getRowById uses a cached dictionary for O(1) lookups
      getRowById: rowId => {
        return get().rowIdToRow[rowId]!;
      },
      clearRowValues: cells => {
        const editableCells = cells.filter(cell => {
          const field = get().table.fields.find(f => f.id === cell.fieldId);
          return field?.isEditable;
        });
        const fieldIdToValueMap = editableCells.reduce(
          (acc, cell) => {
            const field = get().table.fields.find(f => f.id === cell.fieldId);
            if (!field?.isEditable) {
              return acc;
            }
            if (field?.type === 'LINKED_ROW') {
              acc[cell.fieldId] = { linkedRows: [], recommendationStatus: 'COMPLETED' };
            } else {
              acc[cell.fieldId] = null;
            }
            return acc;
          },
          {} as Record<string, TableRowDataType>
        );

        get().updateRows(
          editableCells.map(cell => ({
            id: cell.rowId,
            [cell.fieldId]: fieldIdToValueMap[cell.fieldId]!
          }))
        );
      },
      getValue: (rowId, fieldId) => {
        const field = get().table.fields.find(f => f.id === fieldId);
        const value = get().table.rows.find(row => row.id === rowId)?.[fieldId] ?? null;
        if (field?.type === 'LINKED_ROW' && value === null) {
          return { linkedRows: [], recommendationStatus: 'COMPLETED' } as TableRowLinkedRowType;
        }
        return value;
      },
      updateField: editFieldInTable => {
        set(prev => ({
          ...prev,
          table: {
            ...prev.table,
            fields: prev.table.fields.map(f =>
              f.id === editFieldInTable.id
                ? {
                    ...f,
                    selectConfig: editFieldInTable.selectConfig ?? f.selectConfig,
                    linkedRowConfig: editFieldInTable.linkedRowConfig ?? f.linkedRowConfig,
                    numberConfig: editFieldInTable.numberConfig ?? f.numberConfig,
                    name: editFieldInTable.name ?? f.name
                  }
                : f
            )
          }
        }));
        void editFieldAction(editFieldInTable);
      },
      viewChangeProposalDiff: () => {
        set(prev => ({ ...prev, isViewingChangeProposalDiff: true }));
      },
      hideChangeProposalDiff: () => {
        set(prev => ({ ...prev, isViewingChangeProposalDiff: false }));
      },
      updateActiveViewState: (
        newState: Partial<ViewState>,
        options?: { shouldSaveToDb?: boolean }
      ) => {
        const activeView = get().table.activeView;
        const updatedState: ViewState = {
          ...activeView.state,
          ...newState
        };

        set(prev => ({
          ...prev,
          table: { ...prev.table, activeView: { ...prev.table.activeView, state: updatedState } }
        }));
        if (options?.shouldSaveToDb === undefined || options?.shouldSaveToDb) {
          void updateTableViewStateAction({ state: updatedState, viewId: activeView.id });
        }
      },
      approveProposedChanges: async (rowIds: string[]) => {
        const table = get().table;
        if (!table.changeProposalId) {
          return;
        }
        const rowsWithProposedChanges = table.rows.filter(row => row.proposedChange !== null);
        await approveChangeProposalAction({
          tableId: table.id,
          childTableId: table.childTableId,
          changeProposalId: table.changeProposalId,
          toUpsertRows: rowsWithProposedChanges
            .filter(row => rowIds.includes(row.id))
            .filter(
              row => row.proposedChange?.type === 'INSERT' || row.proposedChange?.type === 'UPDATE'
            )
            .map(convertDtoToUpsertTableRow),
          toDeleteRows: rowsWithProposedChanges
            .filter(row => rowIds.includes(row.id))
            .filter(row => row.proposedChange?.type === 'DELETE')
            .map(row => ({ id: row.id }))
        });
        get().hideChangeProposalDiff();
        toaster.create({
          title: 'Changes Approved',
          description: 'Changes have been approved',
          type: 'info'
        });
      },
      declineProposedChanges: async (rowIds: string[]) => {
        const table = get().table;
        if (!table.changeProposalId) {
          return;
        }
        set(prev => ({
          ...prev,
          table: {
            ...prev.table,
            rows: table.rows
              .map(row => {
                if (row.proposedChange && rowIds.includes(row.id)) {
                  if (row.proposedChange.type === 'INSERT') {
                    return null;
                  } else if (row.proposedChange.type === 'UPDATE') {
                    const oldValue = row.proposedChange.oldValue;
                    return { ...row, proposedChange: null, ...oldValue };
                  } else if (row.proposedChange.type === 'DELETE') {
                    return { ...row, proposedChange: null };
                  }
                }
                return row;
              })
              .filter(row => row !== null) as TableRowDtoUi[]
          }
        }));
        await declineChangeProposalAction({
          tableId: table.id,
          changeProposalId: table.changeProposalId,
          rowIds
        });
        get().hideChangeProposalDiff();
        toaster.create({
          title: 'Changes Declined',
          description: 'Changes have been declined',
          type: 'info'
        });
      }
    }))
);
