import { COLOR_MAP, pickColor } from '@company/common/types';
import { TagProps } from '@company/ui/components';

export const getStyleConfig = (
  args:
    | {
        recommendationScore: number | null;
      }
    | { rowId: string; usedAi: boolean }
): {
  colorPalette: TagProps['colorPalette'];
  variant: TagProps['variant'];
} => {
  if ('usedAi' in args) {
    if (!args.usedAi) {
      return {
        colorPalette: COLOR_MAP[pickColor(args.rowId)].palette,
        variant: 'solid'
      };
    }
  }

  if ('recommendationScore' in args) {
    if (args.recommendationScore && args.recommendationScore > 80) {
      return {
        colorPalette: 'green',
        variant: 'solid'
      };
    }
    if (args.recommendationScore && args.recommendationScore > 70) {
      return {
        colorPalette: 'yellow',
        variant: 'solid'
      };
    }
    if (args.recommendationScore && args.recommendationScore > 55) {
      return {
        colorPalette: 'orange',
        variant: 'solid'
      };
    }

    if (!args.recommendationScore) {
      return {
        colorPalette: 'gray',
        variant: 'solid'
      };
    }

    return {
      colorPalette: 'gray',
      variant: 'solid'
    };
  }

  return {
    colorPalette: 'gray',
    variant: 'solid'
  };
};
