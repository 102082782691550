'use client';

import { toUTCDate } from '@company/common/lib';
import { TableRowDto } from '@company/common/types';
import type { LinkedRowRecommendationRun as LinkedRowRecommendationRunType } from '@company/database/core';
import { getLinkedRowRecommendationRunRealtimeChannelName } from '@company/supabase/realtime';
import { useRealtime } from '@hooks/use-realtime';
import { trpc } from '@server/trpc';
import { useShallow } from 'zustand/react/shallow';
import { useTableStore } from '../stores/table-store';
import { UpdateTableRow } from '../types';

type LinkedRowRecommendationRun = Omit<
  LinkedRowRecommendationRunType,
  'createdOn' | 'updatedOn'
> & {
  createdOn: string;
  updatedOn: string;
};

interface UseSubscribeToLinkedRowChangesProps {
  tableId: string;
  updateRows: (
    rows: UpdateTableRow[],
    options?: {
      shouldSaveChanges: boolean;
    }
  ) => void;
  addRowsToLinkedRowTables: ({ rows, tableId }: { rows: TableRowDto[]; tableId: string }) => void;
}

export const useSubscribeToLinkedRowChanges = ({
  tableId,
  updateRows,
  addRowsToLinkedRowTables
}: UseSubscribeToLinkedRowChangesProps) => {
  const trpcUtils = trpc.useUtils();
  const { refetchTable } = useTableStore(useShallow(({ refetchTable }) => ({ refetchTable })));

  useRealtime<LinkedRowRecommendationRun>({
    channelName: getLinkedRowRecommendationRunRealtimeChannelName(tableId),
    subscription: {
      schema: 'public',
      table: 'LinkedRowRecommendationRun',
      filter: `tableId=eq.${tableId}`
    },
    processEvent: async payload => {
      if (payload.eventType === 'UPDATE') {
        const { rows } = await trpcUtils.table.linkedRow.getLinkedRowValues.fetch({
          runId: payload.new.id,
          tableId,
          updatedAfter:
            payload.new.status === 'COMPLETED'
              ? undefined // Refetch all rows if the recommendation run is completed
              : toUTCDate(payload.new.createdOn)
        });

        void updateRows(rows, { shouldSaveChanges: false });
        void addRowsToLinkedRowTables({ rows, tableId });

        if (payload.new.status === 'COMPLETED') {
          void refetchTable();
        }
      }
    },
    refetchData: async lastSyncDate => {
      const { rows } = await trpcUtils.table.linkedRow.getLinkedRowValues.fetch({
        tableId,
        updatedAfter: lastSyncDate
      });

      void updateRows(rows, { shouldSaveChanges: false });
      void addRowsToLinkedRowTables({ rows, tableId });
    }
  });
};
