import { COLOR_MAP, FieldSelectOption, pickColor, removeNullish } from '@company/common/types';
import {
  Box,
  Button,
  Flex,
  IconButton,
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
  Separator,
  Stack,
  Tag,
  Text
} from '@company/ui/components';
import { CheckIcon, ChevronDownIcon } from '@company/ui/icons';
import { useTableStore } from '@components/table/stores/table-store';
import { Trans } from '@lingui/react/macro';
import { useAuthUser } from '@providers/auth-user-provider';
import React from 'react';
import { useGrid } from '../provider';
import { CellEditorProps, CellRendererProps } from './types';

export const MultiSelectCell: React.FC<CellRendererProps> = props => {
  const { updateRows } = useTableStore();

  const { field } = props;
  const values = props.value as string[] | null | undefined;
  const options = values
    ?.map(value => field.selectConfig?.options.find(option => option.label === value))
    .filter(Boolean) as FieldSelectOption[] | undefined;

  const onStartEdit = () => {
    props.api.startEditingCell({ colKey: field.id, rowIndex: props.node.rowIndex! });
  };

  const onClose = (option: FieldSelectOption) => {
    updateRows([
      {
        id: props.row.id,
        [field.id]: values?.filter(value => value !== option.label) ?? null
      }
    ]);
  };
  return (
    <CellContent
      options={options ?? []}
      onClose={onClose}
      onStartEdit={onStartEdit}
      canEdit={props.row.isLeafRow && props.field.isEditable}
    />
  );
};

export const MultiSelectCellEditor: React.FC<CellEditorProps> = props => {
  const { field } = props;

  const [selectedValues, setSelectedValues] = React.useState<string[]>(
    (props.value as string[]) || []
  );
  const [isOpen, setIsOpen] = React.useState(true);

  const options = selectedValues
    .map(value => field.selectConfig?.options.find(option => option.label === value))
    .filter(removeNullish);

  const { onOpenAddOptionDialog } = useGrid();
  const { updateField } = useTableStore();
  const { localeCode } = useAuthUser();

  // Add explicit keyboard event handler for the Delete key
  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // Check if Delete key is pressed
      if (e.key === 'Delete' || e.key === 'Backspace') {
        props.onValueChange([]);
        props.stopEditing();
      }
    };

    // Add the event listener to the document
    document.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [props]);

  const onSelect = (option: FieldSelectOption) => {
    const currentValues = [...selectedValues];
    const optionIndex = currentValues.indexOf(option.label);

    if (optionIndex === -1) {
      // Add option if not already selected
      props.onValueChange([...currentValues, option.label]);
      setSelectedValues([...currentValues, option.label]);
    } else {
      // Remove option if already selected
      currentValues.splice(optionIndex, 1);
      props.onValueChange(currentValues);
      setSelectedValues(currentValues);
    }
    // Don't close popover on selection
  };

  const onSetEmpty = () => {
    props.onValueChange([]);
    props.stopEditing();
  };

  const onAddOption = (optionLabel: string) => {
    const newOption: FieldSelectOption = {
      label: optionLabel,
      color: COLOR_MAP[pickColor(optionLabel)].palette
    };

    updateField({
      ...field,
      selectConfig: {
        ...field.selectConfig!,
        options: [...(field.selectConfig?.options ?? []), newOption]
      }
    });

    props.onValueChange([...selectedValues, newOption.label]);
    setSelectedValues([...selectedValues, newOption.label]);
    props.stopEditing();
  };

  const onTagClose = (option: FieldSelectOption) => {
    props.onValueChange(selectedValues.filter(value => value !== option.label));
    setSelectedValues(selectedValues.filter(value => value !== option.label));
    props.stopEditing();
  };

  const onClose = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    props.stopEditing();
  };

  React.useEffect(() => {
    setSelectedValues((props.value as string[]) ?? []);
  }, [props.value]);

  return (
    <>
      <PopoverRoot
        open={isOpen}
        modal={false}
        onFocusOutside={onClose}
        onInteractOutside={onClose}
        positioning={{
          fitViewport: true,
          placement: 'bottom-start'
        }}
      >
        <PopoverTrigger asChild>
          <Box w={'full'} h={'full'}>
            <CellContent
              options={options}
              onClose={onTagClose}
              onStartEdit={() => {
                setIsOpen(true);
              }}
              canEdit={props.row.isLeafRow && props.field.isEditable}
            />
          </Box>
        </PopoverTrigger>
        <PopoverContent
          portalled={true}
          minW={'140px'}
          w={'auto'}
          maxW={'380px'}
          maxH={'540px'}
          py={1}
          onPointerDownCapture={e => {
            // Prevent the popover from stealing focus when clicked
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Stack
            gap={0}
            maxH={'540px'}
            overflowY={'auto'}
            css={{
              '&::-webkit-scrollbar': {
                width: '4px'
              },
              '&::-webkit-scrollbar-track': {
                width: '6px'
              }
            }}
          >
            {field.selectConfig?.options
              .sort((a, b) => a.label.localeCompare(b.label, localeCode))
              .map(option => (
                <Button
                  key={option.label}
                  mx={1}
                  variant={selectedValues.includes(option.label) ? 'subtle' : 'ghost'}
                  size={'xs'}
                  textAlign={'left'}
                  justifyContent={'flex-start'}
                  fontSize={'xs'}
                  fontWeight={'normal'}
                  onClick={() => onSelect(option)}
                >
                  {option.label}
                  {selectedValues.includes(option.label) && (
                    <CheckIcon ml={'auto'} color={'primary.600'} />
                  )}
                </Button>
              ))}
          </Stack>
          <Separator my={1} />
          <Button
            variant={'ghost'}
            mx={1}
            size={'xs'}
            textAlign={'left'}
            justifyContent={'flex-start'}
            fontSize={'xs'}
            fontWeight={'normal'}
            onClick={onSetEmpty}
          >
            <Trans>Clear selection</Trans>
          </Button>
          <Button
            variant={'ghost'}
            mx={1}
            size={'xs'}
            textAlign={'left'}
            justifyContent={'flex-start'}
            fontSize={'xs'}
            fontWeight={'normal'}
            onClick={() => {
              setIsOpen(false);
              onOpenAddOptionDialog({
                fieldId: field.id,
                rowId: props.row.id,
                onAddOption: onAddOption
              });
            }}
          >
            <Trans>Add new option</Trans>
          </Button>
        </PopoverContent>
      </PopoverRoot>
    </>
  );
};

const CellContent = ({
  options,
  onClose,
  onStartEdit,
  canEdit
}: {
  options: FieldSelectOption[];
  onClose: (option: FieldSelectOption) => void;
  onStartEdit: () => void;
  canEdit: boolean;
}) => {
  return (
    <Flex align={'center'} gap={1} h={'full'} w={'full'}>
      <Flex align={'center'} gap={1} h={'full'} w={'full'} wrap={'wrap'}>
        {options && options.length > 0
          ? options.map((option, index) => (
              <Tag
                key={index}
                size={'sm'}
                colorPalette={option?.color}
                variant={'surface'}
                h={'18px'}
                closable
                onClose={() => onClose(option)}
              >
                {option?.label}
              </Tag>
            ))
          : null}
      </Flex>
      {canEdit && (
        <IconButton
          size={'xs'}
          h={'6'}
          minH={'6'}
          w={'6'}
          minW={'6'}
          variant={'subtle'}
          onClick={onStartEdit}
          className="display-on-cell-hover"
          ml={'auto'}
        >
          <ChevronDownIcon fontSize={'14px'} />
        </IconButton>
      )}
    </Flex>
  );
};
