'use client';

import { rowValueToString } from '@company/common/lib';
import { TableRowLinkedRowType } from '@company/common/types';
import {
  Flex,
  IconButton,
  IconButtonProps,
  Spinner,
  Tag,
  Text,
  Tooltip
} from '@company/ui/components';
import { BrainIcon, Maximize2Icon, PlusIcon, TriangleAlertIcon } from '@company/ui/icons';
import { useLinkedRow } from '@components/table/providers/linked-row-provider';
import { useTableStore } from '@components/table/stores/table-store';
import { Trans } from '@lingui/react/macro';
import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import { LinkedRowSearchPopover } from '../common';
import { getStyleConfig } from '../linked-row/utils';
import { useGrid } from '../provider';
import { CellRendererProps } from './types';

export const LinkedRowCell = React.memo((props: CellRendererProps) => {
  const { updateRows } = useTableStore(
    useShallow(state => ({
      updateRows: state.updateRows
    }))
  );
  const { onOpenLinkedRowDialog } = useGrid();
  const { getLinkedRowWarningMessages, wasLinkedRowRememberedByAi } = useLinkedRow();

  const [isSearchPopoverOpen, setIsSearchPopoverOpen] = React.useState(false);

  const value = props.row[props.field.id] as TableRowLinkedRowType | null | undefined;
  const cellRef = React.useRef<HTMLDivElement | null>(null);

  const warningMessage = React.useMemo(
    () => getLinkedRowWarningMessages({ fieldId: props.field.id, rowId: props.row.id }).join(', '),
    [props.field.id, props.row.id, getLinkedRowWarningMessages]
  );

  const wasRememberedByAi = React.useMemo(
    () => wasLinkedRowRememberedByAi({ fieldId: props.field.id, rowId: props.row.id }),
    [props.field.id, props.row.id, wasLinkedRowRememberedByAi]
  );

  const onRemove = React.useCallback(
    (linkedRowId: string) => {
      const nextValue: TableRowLinkedRowType = {
        linkedRows: value!.linkedRows.filter(row => row.linkedRowId !== linkedRowId),
        recommendationStatus: value!.recommendationStatus
      };
      void updateRows([
        {
          id: props.row.id,
          [props.field.id]: nextValue
        }
      ]);
    },
    [props.row.id, props.field.id, updateRows, value]
  );

  const onMaximize = React.useCallback(() => {
    onOpenLinkedRowDialog({
      rowId: props.row.id,
      fieldId: props.field.id
    });
  }, [props.row.id, props.field.id, onOpenLinkedRowDialog]);

  const focusCell = React.useCallback(() => {
    const rowIndex = props.api.getRowNode(props.row.id)?.rowIndex;
    if (rowIndex && props.column) {
      props.api.setFocusedCell(rowIndex, props.column.getId());
    }
  }, [props.api, props.row.id, props.column]);

  const onMaximizeClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      onMaximize();
    },
    [onMaximize]
  );

  const openSearchPopover = React.useCallback(() => {
    setIsSearchPopoverOpen(true);
  }, [setIsSearchPopoverOpen]);

  if (!value) {
    return null;
  }

  if (value?.recommendationStatus === 'IN_PROGRESS') {
    return (
      <Flex w={'full'} h={'full'} align={'center'} gap={2}>
        <Spinner size={'xs'} />
        <Text fontSize={'xs'} color={'gray.500'}>
          <Trans>Processing</Trans>...
        </Text>
      </Flex>
    );
  }

  const linkedRows = value?.linkedRows ?? [];

  return (
    <Flex
      ref={cellRef}
      w={'full'}
      h={'full'}
      justify={'space-between'}
      align={'center'}
      gap={1}
      position={'relative'}
    >
      {linkedRows.length > 0 && (
        <Flex w={'full'} h={'full'} align={'center'} wrap={'wrap'} gap={1}>
          {linkedRows.map(({ linkedRowId, primaryFieldValue, recommendationScore }, index) => (
            <LinkedRowTag
              key={index}
              linkedRowId={linkedRowId}
              primaryFieldValue={primaryFieldValue}
              recommendationScore={recommendationScore}
              usedAi={props.field.linkedRowConfig?.useAi ?? false}
              onRemove={onRemove}
            />
          ))}
        </Flex>
      )}
      {wasRememberedByAi && (
        <InfoButtonWithTooltip
          icon={<BrainIcon fontSize={'14px'} />}
          tooltipContent={<Trans>This was learned by the AI-Agent</Trans>}
          colorPalette={'purple'}
        />
      )}
      {warningMessage.length > 0 && (
        <InfoButtonWithTooltip
          icon={<TriangleAlertIcon fontSize={'14px'} />}
          tooltipContent={warningMessage}
          colorPalette={'orange'}
        />
      )}
      {props.row.isLeafRow && props.field.isEditable && (
        <Flex h={'full'} className="display-on-cell-hover" align={'center'} gap={1} ml={'auto'}>
          <IconButton
            size={'xs'}
            h={'6'}
            minH={'6'}
            w={'6'}
            minW={'6'}
            variant={'subtle'}
            onClick={openSearchPopover}
          >
            <PlusIcon fontSize={'14px'} />
          </IconButton>
          {props.field.linkedRowConfig?.useAi && (
            <IconButton
              aria-label="Remove"
              variant="surface"
              size="xs"
              onClick={onMaximizeClick}
              h={'6'}
              minH={'6'}
              w={'6'}
              minW={'6'}
              colorPalette={'primary'}
            >
              <Maximize2Icon fontSize={'13.5px'} />
            </IconButton>
          )}
        </Flex>
      )}
      {isSearchPopoverOpen && (
        <LinkedRowSearchPopover
          ref={cellRef}
          field={props.field}
          rowId={props.row.id}
          cellValue={value}
          isOpen={isSearchPopoverOpen}
          setIsOpen={setIsSearchPopoverOpen}
          returnFocus={focusCell}
        />
      )}
    </Flex>
  );
});

interface LinkedRowTagProps {
  linkedRowId: string;
  primaryFieldValue: string | number | boolean | Date | string[] | null;
  recommendationScore: number | null;
  usedAi: boolean;
  onRemove: (linkedRowId: string) => void;
}

const LinkedRowTag = React.memo(
  ({
    linkedRowId,
    primaryFieldValue,
    recommendationScore,
    usedAi,
    onRemove
  }: LinkedRowTagProps) => {
    const [isHovering, setIsHovering] = React.useState(false);

    const rowValueAsString = React.useMemo(
      () => rowValueToString(primaryFieldValue),
      [primaryFieldValue]
    );
    const styleConfig = React.useMemo(
      () => getStyleConfig({ recommendationScore, rowId: linkedRowId, usedAi }),
      [recommendationScore, linkedRowId, usedAi]
    );

    const truncatedRowValueAsString = React.useMemo(() => {
      if (rowValueAsString.length > 35) {
        return `${rowValueAsString.slice(0, 32)}...`;
      }
      return rowValueAsString;
    }, [rowValueAsString]);

    const onRemoveClick = React.useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        onRemove(linkedRowId);
      },
      [linkedRowId, onRemove]
    );

    return (
      <Tooltip
        content={`${recommendationScore ? `(${recommendationScore.toFixed(1)}%) ` : ''}${rowValueAsString}`}
        portalled={true}
        lazyMount={true}
        disabled={!isHovering}
      >
        <Tag
          size={'sm'}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          colorPalette={styleConfig.colorPalette}
          variant={styleConfig.variant}
          h={'18px'}
          closable={true}
          onClose={onRemoveClick}
          endElement={recommendationScore && <>{recommendationScore.toFixed(1)}%</>}
        >
          {truncatedRowValueAsString}
        </Tag>
      </Tooltip>
    );
  }
);

const InfoButtonWithTooltip = React.memo(
  ({
    icon,
    tooltipContent,
    colorPalette
  }: {
    icon: React.ReactNode;
    tooltipContent: React.ReactNode;
    colorPalette: IconButtonProps['colorPalette'];
  }) => {
    const [isHovering, setIsHovering] = React.useState(false);
    return (
      <Tooltip content={tooltipContent} portalled={true} lazyMount={true} disabled={!isHovering}>
        <IconButton
          size={'xs'}
          h={'6'}
          minH={'6'}
          w={'6'}
          minW={'6'}
          variant={'subtle'}
          colorPalette={colorPalette}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          {icon}
        </IconButton>
      </Tooltip>
    );
  }
);
