'use client';

import {
  isHtmlString,
  isTableRowLinkedRowTypeArray,
  htmlToFormattedString
} from '@company/common/lib';
import { TableRowDataType } from '@company/common/types';
import { Box, Stack, Text } from '@company/ui/components';
import React from 'react';

interface TableRowValueRendererProps {
  value: TableRowDataType | undefined;
}

export const TableRowValueRenderer = ({ value }: TableRowValueRendererProps) => {
  if (value === null || value === undefined) {
    return null;
  }
  if (value instanceof Date) {
    return <Box>{value.toLocaleDateString()}</Box>;
  }
  if (isTableRowLinkedRowTypeArray(value)) {
    return <Box>{value.linkedRows.map(row => `${row.primaryFieldValue}`).join(', ')}</Box>;
  }

  if (Array.isArray(value)) {
    return <Box>{value.join(', ')}</Box>;
  }

  if (typeof value === 'boolean') {
    return <Box>{value ? 'true' : 'false'}</Box>;
  }
  if (typeof value === 'string') {
    if (isHtmlString(value)) {
      const text = htmlToFormattedString(value);
      const paragraphs = text.split('\n\n');
      return (
        <Stack gap={2}>
          {paragraphs.map((paragraph, index) => (
            <Box key={index}>
              {paragraph.split('\n').map((line, index) => (
                <Text key={index}>{line}</Text>
              ))}
            </Box>
          ))}
        </Stack>
      );
    }
    return <Box>{value}</Box>;
  }

  if (typeof value === 'number') {
    return <Box>{value}</Box>;
  }

  throw new Error('Unsupported value type');
};
