'use client';

import { Button, Dialog, Flex, Form, Stack, TextInput } from '@company/ui/components';
import { useResetForm, useZodForm } from '@company/ui/hooks';
import { useLingui } from '@lingui/react';
import { z } from 'zod';
import { OpenedAddOptionDialog } from '../views/grid/types';
import { Trans } from '@lingui/react/macro';
import { msg } from '@lingui/core/macro';

interface AddSelectOptionDialogProps {
  openedAddOptionDialog: OpenedAddOptionDialog | null;
  onClose: () => void;
}

export const AddSelectOptionDialog = ({
  openedAddOptionDialog,
  onClose
}: AddSelectOptionDialogProps) => {
  const { _ } = useLingui();

  const form = useZodForm({
    schema: z.object({
      option: z.string().min(1)
    })
  });
  useResetForm({
    form,
    isOpen: !!openedAddOptionDialog,
    values: {
      option: ''
    }
  });

  const onSubmit = form.handleSubmit(data => {
    if (!openedAddOptionDialog) {
      return;
    }

    openedAddOptionDialog.onAddOption(data.option);
    onClose();
  });

  return (
    <Dialog.Root open={!!openedAddOptionDialog} onFocusOutside={() => onClose()}>
      <Dialog.Backdrop />
      {openedAddOptionDialog && (
        <Dialog.Positioner>
          <Dialog.Content>
            <Dialog.Header pb={0}>
              <Dialog.Title mb={4}>
                <Trans>Add new option</Trans>
              </Dialog.Title>
            </Dialog.Header>

            <Dialog.Body pt={0}>
              <Form onSubmit={onSubmit}>
                <Stack gap={4}>
                  <TextInput
                    autoFocus
                    form={form}
                    name={'option'}
                    placeholder={_(msg`Enter a name`)}
                  />
                  <Flex ml={'auto'} gap={2}>
                    <Button variant={'subtle'} size={'sm'} onClick={() => onClose()}>
                      <Trans>Cancel</Trans>
                    </Button>
                    <Button
                      disabled={!form.formState.isValid}
                      type={'submit'}
                      colorPalette={'primary'}
                      size={'sm'}
                    >
                      <Trans>Save</Trans>
                    </Button>
                  </Flex>
                </Stack>
              </Form>
            </Dialog.Body>
          </Dialog.Content>
        </Dialog.Positioner>
      )}
    </Dialog.Root>
  );
};
