'use client';

import { CustomHeaderProps } from '@ag-grid-community/react';
import { Box, Flex, Text } from '@company/ui/components';
import { CustomGridHeaderProps } from './types';

export const CustomGridColumnHeader = (props: CustomGridHeaderProps & CustomHeaderProps) => {
  const GridHeaderIcon = props.icon;
  return (
    <Box w={'full'}>
      <Flex gap={2} fontSize={'sm'} alignItems={'center'} cursor={'grab'}>
        {GridHeaderIcon && <GridHeaderIcon />}
        <Text>{props.displayName}</Text>
      </Flex>
    </Box>
  );
};
