'use client';

import { tableRowId } from '@company/common/lib';
import { Button, Stack, Text } from '@company/ui/components';
import { useTableStore } from '@components/table/stores/table-store';
import { Trans } from '@lingui/react/macro';
import { useShallow } from 'zustand/react/shallow';

export const GridNoRowsToShow = () => {
  const { activeView, addRow } = useTableStore(
    useShallow(state => ({
      activeView: state.table.activeView,
      addRow: state.addRow
    }))
  );

  return (
    <Stack gap={3} pointerEvents={'auto'}>
      <Text>
        <Trans>No rows to show</Trans>
      </Text>
      {activeView.config.canAddRows && (
        <Button
          onClick={() =>
            addRow({
              row: {
                id: tableRowId()
              }
            })
          }
          size={'sm'}
          colorPalette={'primary'}
        >
          <Trans>Add row</Trans>
        </Button>
      )}
    </Stack>
  );
};
