'use client';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import './styles.css';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  CellContextMenuEvent,
  CellEditingStartedEvent,
  CellSelectionDeleteStartEvent,
  ColumnMovedEvent,
  ColumnResizedEvent,
  GetRowIdParams,
  GridOptions,
  ModuleRegistry,
  ProcessCellForExportParams,
  RowClassParams
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { fieldTypeToExportFormat } from '@company/common/lib';
import { Box, Button, Stack } from '@company/ui/components';
import { useMeasureFPS } from '@company/ui/hooks';
import { AddSelectOptionDialog } from '@components/table/common/add-select-option-dialog';
import { useField } from '@components/table/hooks';
import { TableRowDtoUi } from '@typings/table';
import React, { useRef } from 'react';
import { GridActionBar } from './action-bar';
import { ChildTableDialog } from './child-table';
import { GridCellContextMenu } from './context-menu';
import { LinkedRowDialog } from './linked-row';
import { GridProvider, useGrid } from './provider';
import { QuickFilter } from './quick-filter';
import { GridNoRowsToShow } from './overlay';

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  RowGroupingModule,
  RangeSelectionModule,
  ClipboardModule
]);

export const GridView = ({ header }: { header?: React.ReactNode }) => {
  return (
    <GridProvider>
      <Grid header={header} />
    </GridProvider>
  );
};

const Grid = ({ header }: { header?: React.ReactNode }) => {
  const gridApiRef = useRef<AgGridReact<TableRowDtoUi>>(null!);
  const {
    rows,
    columns,
    components,
    openedChildTableDialog,
    openedLinkedRowDialog,
    openedAddOptionDialog,
    quickFilterText,
    openedCellContextMenu,
    onCloseCellContextMenu,
    onOpenCellContextMenu,
    onCloseChildTableDialog,
    onCloseLinkedRowDialog,
    onCloseAddOptionDialog,
    onColumnResize,
    onColumnMove,
    getRowStyle,
    onClearCellValues,
    shouldCellBePreventedFromEditing
  } = useGrid();
  const { getFieldById } = useField();

  const gridContainerRef = useRef<HTMLDivElement>(null!);
  const gridViewport = gridContainerRef.current?.querySelector('.ag-body-viewport');

  const { startAutoScrolling } = useMeasureFPS(
    gridViewport,
    100, // scrollStep
    100 // scrollInterval
  );

  const onColumnResizedEvent = React.useCallback(
    (event: ColumnResizedEvent<TableRowDtoUi>) => {
      if (event.finished) {
        onColumnResize(event.column!.getColId(), event.column!.getActualWidth());
      }
    },
    [onColumnResize]
  );

  const onColumnMovedEvent = React.useCallback(
    (event: ColumnMovedEvent<TableRowDtoUi>) => {
      if (event.finished && event.toIndex !== undefined) {
        onColumnMove(event.column!.getColId(), event.toIndex);
      }
    },
    [onColumnMove]
  );

  const getRowStyleHandler = React.useCallback(
    (params: RowClassParams<TableRowDtoUi>) => {
      if (!params.data) {
        return;
      }
      return getRowStyle(params.data.id);
    },
    [getRowStyle]
  );

  const onCellSelectionDeleteStart = React.useCallback(
    (event: CellSelectionDeleteStartEvent<TableRowDtoUi>) => {
      const cellRanges = event.api.getCellRanges();
      const cells: { rowId: string; fieldId: string }[] = [];

      cellRanges?.forEach(cellRange => {
        const startRowIndex = cellRange.startRow?.rowIndex;
        const endRowIndex = cellRange.endRow?.rowIndex;
        const columns = cellRange.columns;

        if (startRowIndex == null || endRowIndex == null) return;

        // Ensure the range is inclusive and handles reverse selection
        const [minRowIndex, maxRowIndex] = [
          Math.min(startRowIndex, endRowIndex),
          Math.max(startRowIndex, endRowIndex)
        ];

        for (let i = minRowIndex; i <= maxRowIndex; i++) {
          const rowNode = event.api.getDisplayedRowAtIndex(i);
          if (rowNode) {
            const rowId = rowNode.data?.id;
            if (rowId == null) {
              return;
            }

            columns.forEach(column => {
              const fieldId = column.getColId();
              cells.push({ rowId, fieldId });
            });
          }
        }
      });

      onClearCellValues(cells);
    },
    [onClearCellValues]
  );

  const onCellEditingStarted = React.useCallback(
    (event: CellEditingStartedEvent<TableRowDtoUi>) => {
      if (shouldCellBePreventedFromEditing(event.column.getId())) {
        event.api.stopEditing();
      }
    },
    []
  );

  const getDataPath = React.useCallback((params: TableRowDtoUi) => {
    return params.parentRowIdPath;
  }, []);

  const getRowId = React.useCallback((params: GetRowIdParams<TableRowDtoUi>) => {
    return params.data.id;
  }, []);

  const gridOptions = React.useMemo((): GridOptions<TableRowDtoUi> => {
    return {
      cellSelection: true,
      rowSelection: {
        mode: 'multiRow',
        checkboxes: false,
        headerCheckbox: false,
        enableClickSelection: false,
        copySelectedRows: true,
        enableSelectionWithoutKeys: false
      }
    };
  }, []);

  const processCellForClipboard = React.useCallback(
    (params: ProcessCellForExportParams<TableRowDtoUi>) => {
      const field = getFieldById(params.column.getId());
      if (field == null) {
        return;
      }
      return fieldTypeToExportFormat[field.type].toExportFormat(params.value);
    },
    [getFieldById]
  );

  const processCellFromClipboard = React.useCallback(
    (params: ProcessCellForExportParams<TableRowDtoUi>) => {
      const field = getFieldById(params.column.getId());
      if (field == null) {
        return;
      }
      return fieldTypeToExportFormat[field.type].toTableRowValue(params.value);
    },
    [getFieldById]
  );

  const onCellContextMenu = React.useCallback(
    (params: CellContextMenuEvent<TableRowDtoUi>) => {
      if (params.data == null || params.event == null) {
        return;
      }

      const event = params.event as MouseEvent;
      onOpenCellContextMenu({
        rowId: params.data.id,
        fieldId: params.column.getId(),
        position: {
          x: event.clientX,
          y: event.clientY
        }
      });
    },
    [onOpenCellContextMenu]
  );

  return (
    <>
      <Box width="100%" height="100%">
        <Stack gap={0} height="100%" width="100%" position="relative">
          {header}
          <Box
            ref={gridContainerRef}
            className="ag-theme-quartz"
            width="100%"
            height="100%"
            flexGrow={1}
            flexShrink={1}
            flexBasis={0}
            position={'relative'}
            style={{ overflowY: 'scroll' }}
            onContextMenu={event => event.preventDefault()}
          >
            <AgGridReact
              ref={gridApiRef}
              rowData={rows}
              columnDefs={columns}
              components={components}
              onColumnResized={onColumnResizedEvent}
              onColumnMoved={onColumnMovedEvent}
              getRowStyle={getRowStyleHandler}
              treeData={true}
              treeDataDisplayType="custom"
              groupDefaultExpanded={-1}
              getDataPath={getDataPath}
              getRowId={getRowId}
              quickFilterText={quickFilterText}
              gridOptions={gridOptions}
              onCellSelectionDeleteStart={onCellSelectionDeleteStart}
              onCellEditingStarted={onCellEditingStarted}
              processCellForClipboard={processCellForClipboard}
              processCellFromClipboard={processCellFromClipboard}
              onCellContextMenu={onCellContextMenu}
              suppressContextMenu={true}
              noRowsOverlayComponent={GridNoRowsToShow}
            />
            <QuickFilter />
            <ChildTableDialog
              openedChildTableDialog={openedChildTableDialog}
              onClose={onCloseChildTableDialog}
            />
            <LinkedRowDialog
              openedLinkedRowDialog={openedLinkedRowDialog}
              onClose={onCloseLinkedRowDialog}
            />
            <AddSelectOptionDialog
              openedAddOptionDialog={openedAddOptionDialog}
              onClose={onCloseAddOptionDialog}
            />
            <GridActionBar gridApiRef={gridApiRef} />
            <GridCellContextMenu
              openedCellContextMenu={openedCellContextMenu}
              onCloseCellContextMenu={onCloseCellContextMenu}
            />
            {process.env.NODE_ENV === 'development' && (
              <Button
                position={'absolute'}
                bottom={10}
                right={10}
                size={'xs'}
                variant={'surface'}
                onClick={startAutoScrolling}
              >
                Test FPS
              </Button>
            )}
          </Box>
        </Stack>
      </Box>
    </>
  );
};
