'use client';

import { Box, Flex, Text } from '@company/ui/components';

interface HeaderProps {
  leftAddon?: React.ReactNode;
  rightAddon?: React.ReactNode;
  title: string;
}

export const PieceOfInformationEditorHeader = ({ leftAddon, rightAddon, title }: HeaderProps) => {
  return (
    <Flex
      w={'full'}
      minH={'48px'}
      px={4}
      bgColor={'Background'}
      roundedTop={'lg'}
      justify={'space-between'}
      borderBottomWidth={1}
    >
      <Box h={'full'}>
        <Flex h={'full'} alignItems={'center'} gap={1.5}>
          {leftAddon}
          <Text fontSize={'sm'} fontWeight={'semibold'}>
            {title}
          </Text>
        </Flex>
      </Box>
      <Box h={'full'}>
        <Flex h={'full'} alignItems={'center'} gap={1.5}>
          {rightAddon}
        </Flex>
      </Box>
    </Flex>
  );
};
