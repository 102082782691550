import { COLOR_MAP, FieldSelectOption, pickColor } from '@company/common/types';
import {
  Box,
  Button,
  Flex,
  IconButton,
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
  Separator,
  Stack,
  Tag
} from '@company/ui/components';
import { CheckIcon, ChevronDownIcon } from '@company/ui/icons';
import { useTableStore } from '@components/table/stores/table-store';
import { Trans } from '@lingui/react/macro';
import { useAuthUser } from '@providers/auth-user-provider';
import React from 'react';
import { useGrid } from '../provider';
import { CellEditorProps, CellRendererProps } from './types';

export const SingleSelectCell: React.FC<CellRendererProps> = props => {
  const { field } = props;
  const value = props.value as string | null | undefined;
  const option = field.selectConfig?.options.find(option => option.label === value);

  const onStartEdit = () => {
    props.api.startEditingCell({ colKey: field.id, rowIndex: props.node.rowIndex! });
  };

  return (
    <Flex align={'center'} gap={1} h={'full'} w={'full'}>
      {option && (
        <Tag size={'sm'} colorPalette={option?.color} variant={'surface'} h={'18px'}>
          {option?.label}
        </Tag>
      )}
      {props.row.isLeafRow && props.field.isEditable && (
        <IconButton
          size={'xs'}
          h={'6'}
          minH={'6'}
          w={'6'}
          minW={'6'}
          variant={'subtle'}
          onClick={onStartEdit}
          className="display-on-cell-hover"
          ml={'auto'}
        >
          <ChevronDownIcon fontSize={'14px'} />
        </IconButton>
      )}
    </Flex>
  );
};

export const SingleSelectCellEditor: React.FC<CellEditorProps> = props => {
  const { field } = props;

  const value = props.value as string | null | undefined;
  const option = field.selectConfig?.options.find(option => option.label === value);

  const { onOpenAddOptionDialog } = useGrid();
  const { updateField } = useTableStore();
  const { localeCode } = useAuthUser();

  // Add explicit keyboard event handler for the Delete key
  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // Check if Delete key is pressed
      if (e.key === 'Delete' || e.key === 'Backspace') {
        props.onValueChange(null);
        props.stopEditing();
      }
    };

    // Add the event listener to the document
    document.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [props]);

  const onSelect = (option: FieldSelectOption) => {
    props.onValueChange(option.label);
    props.stopEditing();
  };

  const onSetEmpty = () => {
    props.onValueChange(null);
    props.stopEditing();
  };

  const onAddOption = (optionLabel: string) => {
    const newOption: FieldSelectOption = {
      label: optionLabel,
      color: COLOR_MAP[pickColor(optionLabel)].palette
    };

    updateField({
      ...field,
      selectConfig: {
        ...field.selectConfig!,
        options: [...(field.selectConfig?.options ?? []), newOption]
      }
    });

    props.onValueChange(newOption.label);
    props.stopEditing();
  };

  const onClose = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    props.stopEditing();
  };

  return (
    <>
      <PopoverRoot
        open={true}
        modal={false}
        onFocusOutside={onClose}
        onInteractOutside={onClose}
        positioning={{
          fitViewport: true,
          placement: 'bottom-start'
        }}
      >
        <PopoverTrigger asChild>
          {option ? (
            <Tag size={'sm'} colorPalette={option?.color} variant={'surface'} h={'18px'}>
              {option?.label}
            </Tag>
          ) : (
            <Box w={'full'} h={'full'}></Box>
          )}
        </PopoverTrigger>
        <PopoverContent
          portalled={true}
          minW={'140px'}
          w={'auto'}
          maxW={'380px'}
          maxH={'540px'}
          py={1}
          onPointerDownCapture={e => {
            // Prevent the popover from stealing focus when clicked
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Stack
            gap={0}
            maxH={'540px'}
            overflowY={'auto'}
            css={{
              '&::-webkit-scrollbar': {
                width: '4px'
              },
              '&::-webkit-scrollbar-track': {
                width: '6px'
              }
            }}
          >
            {field.selectConfig?.options
              .sort((a, b) => a.label.localeCompare(b.label, localeCode))
              .map(option => (
                <Button
                  key={option.label}
                  mx={1}
                  variant={'ghost'}
                  size={'xs'}
                  textAlign={'left'}
                  justifyContent={'flex-start'}
                  fontSize={'xs'}
                  fontWeight={'normal'}
                  onClick={() => onSelect(option)}
                >
                  {option.label}
                  {option.label === value && <CheckIcon ml={'auto'} />}
                </Button>
              ))}
          </Stack>
          <Separator my={1} />
          <Button
            variant={'ghost'}
            mx={1}
            size={'xs'}
            textAlign={'left'}
            justifyContent={'flex-start'}
            fontSize={'xs'}
            fontWeight={'normal'}
            onClick={onSetEmpty}
          >
            <Trans>Set to empty</Trans>
          </Button>
          <Button
            variant={'ghost'}
            mx={1}
            size={'xs'}
            textAlign={'left'}
            justifyContent={'flex-start'}
            fontSize={'xs'}
            fontWeight={'normal'}
            onClick={() =>
              onOpenAddOptionDialog({
                fieldId: field.id,
                rowId: props.row.id,
                onAddOption: onAddOption
              })
            }
          >
            <Trans>Add new option</Trans>
          </Button>
        </PopoverContent>
      </PopoverRoot>
    </>
  );
};
