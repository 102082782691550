'use client';

import { sanitizeFilename } from '@company/common/lib';
import { logger } from '@company/common/logger';
import { FileKnowledgeLibraryConfig } from '@company/common/types';
import { createFileAction, getSignedUploadUrlAction } from '@server/actions/file';
import { getOrganizationConfigurationAction } from '@server/actions/organization/config';

type FileUploadStatus = 'UPLOADING' | 'UPLOADED' | 'ERROR';

export const uploadFile = async ({
  fileId,
  file,
  knowledgeLibrary,
  onProgress
}: {
  fileId: string;
  file: File;
  knowledgeLibrary: FileKnowledgeLibraryConfig;
  onProgress: (progress: number) => void;
}): Promise<{
  id: string;
  status: FileUploadStatus;
  errorMessage?: string;
  pieceOfInformationId: string | null;
}> => {
  file = await preprocessFileContent(file);
  try {
    const uniqueFileName = `${fileId}-${sanitizeFilename(file.name)}`;
    const signedUrlResponse = await getSignedUploadUrlAction({ fileName: uniqueFileName });
    if (!signedUrlResponse?.data) {
      throw new Error('Failed to get signed upload url');
    }

    const { signedUrl, path } = signedUrlResponse.data;

    // Use XMLHttpRequest to track upload progress
    await new Promise<void>(async (resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', signedUrl);

      xhr.upload.onprogress = event => {
        if (event.lengthComputable && onProgress) {
          const progress = (event.loaded / event.total) * 100;
          onProgress(progress); // Update progress
        }
      };

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve();
        } else {
          reject(new Error(`HTTP error! status: ${xhr.status}`));
        }
      };

      xhr.onerror = () => reject(new Error('Failed to upload file'));
      xhr.setRequestHeader('Content-Type', file.type);
      xhr.send(file);
    });

    const createdFileResponse = await createFileAction({
      id: fileId,
      name: file.name,
      sizeInBytes: file.size,
      fileUploadPath: path,
      knowledgeLibrary
    });

    if (!createdFileResponse?.data) {
      throw new Error('Failed to create file');
    }

    return {
      id: createdFileResponse.data.id,
      status: 'UPLOADED',
      pieceOfInformationId: createdFileResponse.data.pieceOfInformationId
    };
  } catch (error) {
    return {
      id: fileId,
      status: 'ERROR',
      errorMessage: 'Failed to upload file',
      pieceOfInformationId: null
    };
  }
};

const preprocessFileContent = async (file: File): Promise<File> => {
  const fileExtension = file.name.split('.').pop()!.toLowerCase();

  if (fileExtension !== 'xml') {
    return file;
  }

  const response = await getOrganizationConfigurationAction();

  if (!response?.data) {
    return file;
  }

  try {
    const text = await file.text();
    if (
      text.includes('<EstimateRoot>') &&
      !response.data.agents?.costEstimator?.shouldKeepCostCalculationPrices
    ) {
      const processedText = text.replace(/<URValue>[^<]*<\/URValue>/g, '');

      return new File([processedText], file.name, { type: file.type });
    }

    return file;
  } catch (error) {
    logger.error('Error preprocessing XML file:', error);
    return file;
  }
};
