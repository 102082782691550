'use client';

import { formatDate, getFullFileName, removeDuplicates, toUTCDate } from '@company/common/lib';
import { COLOR_MAP, EMAIL_STATUSES } from '@company/common/types';
import { Email, EmailStatus } from '@company/database/core';
import { getEmailDraftChangeRealtimeChannelName } from '@company/supabase/realtime';
import {
  Box,
  Button,
  Center,
  DocumentEditor,
  Flex,
  Form,
  HoverCardArrow,
  HoverCardContent,
  HoverCardRoot,
  HoverCardTrigger,
  IconButton,
  Input,
  MenuContent,
  MenuItem,
  MenuRoot,
  MenuTrigger,
  PopoverBody,
  PopoverContent,
  PopoverRoot,
  PopoverTitle,
  PopoverTrigger,
  Separator,
  Spinner,
  Stack,
  Tag,
  Text,
  TextInput,
  toaster
} from '@company/ui/components';
import { useDebouncedCallback, useDisclosure, useResetForm, useZodForm } from '@company/ui/hooks';
import { ChevronDownIcon, FileIcon, InfoIcon } from '@company/ui/icons';
import { useRealtime } from '@hooks/use-realtime';
import { downloadFile } from '@lib/file';
import { msg } from '@lingui/core/macro';
import { useLingui } from '@lingui/react';
import { Trans } from '@lingui/react/macro';
import { useAuthUser } from '@providers/auth-user-provider';
import { sendEmailDraftAction, updateEmailDraftAction } from '@server/actions/email';
import { trpc } from '@server/trpc';
import React from 'react';
import { z } from 'zod';
import { PieceOfInformationEditorHeader } from './header';

type EmailDtoWithDateString = Omit<Email, 'sentOn' | 'createdOn' | 'updatedOn'> & {
  sentOn: string | null;
  createdOn: string;
  updatedOn: string;
};

type UpdateEmailDraftData = {
  body?: string;
  to?: string[];
  replyTo?: string[];
  from?: string;
  cc?: string[];
  bcc?: string[];
  subject?: string;
  attachments?: { fileId: string; name: string; extension: string }[];
};

interface HeaderProps {
  leftAddon: React.ReactNode;
  rightAddon: React.ReactNode;
}

interface PieceOfInformationEmailDraftEditorProps {
  emailDraft: {
    id: string;
    name: string;
    subject: string;
  };
  header: HeaderProps;
}

export const PieceOfInformationEmailDraftEditor = ({
  emailDraft,
  header
}: PieceOfInformationEmailDraftEditorProps) => {
  const trpcUtils = trpc.useUtils();

  const { data, isPending } = trpc.emailDraft.getById.useQuery({ emailDraftId: emailDraft.id });

  useRealtime<EmailDtoWithDateString>({
    channelName: getEmailDraftChangeRealtimeChannelName(emailDraft.id),
    subscription: {
      table: 'Email',
      schema: 'public',
      filter: `emailDraftId=eq.${emailDraft.id}`
    },
    processEvent: payload => {
      const query = trpcUtils.emailDraft.getById;
      query.cancel();
      query.setData({ emailDraftId: emailDraft.id }, prev => {
        if (!prev) {
          return prev;
        }
        if (payload.eventType === 'INSERT' || payload.eventType === 'UPDATE') {
          const nextEmails = [
            ...prev.emails.filter(email => email.id !== payload.new.id),
            {
              id: payload.new.id,
              status: payload.new.status,
              to: payload.new.to,
              subject: payload.new.subject,
              sentOn: payload.new.sentOn ? toUTCDate(payload.new.sentOn) : null
            }
          ];
          return {
            ...prev,
            emails: nextEmails
          };
        }

        return prev;
      });
    }
  });
  const [isSending, setIsSending] = React.useState(false);

  const debouncedEmailDraftContentUpdate = useDebouncedCallback(
    async (data: UpdateEmailDraftData) => {
      const queryPath = trpcUtils.emailDraft.getById;
      queryPath.cancel();
      queryPath.setData({ emailDraftId: emailDraft.id }, prev => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          ...data
        };
      });

      await updateEmailDraftAction({ emailDraftId: emailDraft.id, ...data });
    },
    1000,
    [emailDraft.id]
  );

  const onSendClick = async () => {
    setIsSending(true);
    await sendEmailDraftAction({ emailDraftId: emailDraft.id });
    setIsSending(false);
    toaster.success({
      type: 'success',
      title: <Trans>Email(s) sent</Trans>,
      description: <Trans>The email(s) have been sent to {data?.to.length} recipients.</Trans>
    });
  };

  return (
    <Stack gap={0} w={'full'} h={'full'} position={'relative'}>
      <PieceOfInformationEditorHeader
        title={emailDraft.name}
        leftAddon={header.leftAddon}
        rightAddon={
          <>
            {!data?.isTemplate && (
              <>
                <EmailStatistics emails={data?.emails ?? []} />
                <Button
                  colorPalette={'primary'}
                  size={'xs'}
                  onClick={onSendClick}
                  loading={isSending}
                  disabled={isSending}
                  loadingText={<Trans>Sending...</Trans>}
                >
                  <Trans>Send Email</Trans>
                </Button>
              </>
            )}
            {header.rightAddon}
          </>
        }
      />
      {isPending ? (
        <Center h={'full'} w={'full'} gap={4}>
          <Spinner />
          <Text>
            <Trans>Loading email draft...</Trans>
          </Text>
        </Center>
      ) : (
        <Stack
          gap={4}
          mt={4}
          separator={<Separator />}
          w={'full'}
          h={'full'}
          display="flex"
          flexDirection="column"
        >
          <EmailDraftHeader
            emailDraft={data!}
            updateEmailDraft={debouncedEmailDraftContentUpdate}
          />
          <Box flex="1" display="flex" flexDirection="column" overflow="hidden">
            <EmailDraftBody
              emailDraft={data!}
              updateEmailDraft={debouncedEmailDraftContentUpdate}
            />
          </Box>
        </Stack>
      )}
    </Stack>
  );
};

const EmailDraftHeader = ({
  emailDraft,
  updateEmailDraft
}: {
  emailDraft: {
    name: string;
    subject: string;
    isTemplate: boolean;
    to: string[];
    from: string;
    replyTo: string[];
    cc: string[];
    bcc: string[];
    attachments: { fileId: string; name: string; extension: string }[];
  };
  updateEmailDraft: (data: UpdateEmailDraftData) => void;
}) => {
  const [attachments, setAttachments] = React.useState(emailDraft.attachments);

  const removeAttachment = (fileId: string) => {
    setAttachments(attachments.filter(attachment => attachment.fileId !== fileId));
    updateEmailDraft({
      attachments: attachments.filter(attachment => attachment.fileId !== fileId)
    });
  };

  React.useEffect(() => {
    setAttachments(emailDraft.attachments);
  }, [emailDraft.attachments]);

  return (
    <Stack gap={2}>
      {!emailDraft.isTemplate && (
        <>
          <EmailDraftFrom emailDraft={emailDraft} updateEmailDraft={updateEmailDraft} />
          <EmailDraftReplyTo emailDraft={emailDraft} updateEmailDraft={updateEmailDraft} />
          <EmailDraftTo emailDraft={emailDraft} updateEmailDraft={updateEmailDraft} />
          <EmailDraftCc emailDraft={emailDraft} updateEmailDraft={updateEmailDraft} />
          <EmailDraftBcc emailDraft={emailDraft} updateEmailDraft={updateEmailDraft} />
        </>
      )}
      <EmailDraftSubject emailDraft={emailDraft} updateEmailDraft={updateEmailDraft} />
      {attachments.length > 0 && (
        <Box pt={3}>
          <EmailDraftLabelToData label={<Trans>Attachments</Trans>} hasBorderBottom={false}>
            <Flex gap={2} flexWrap={'wrap'} w={'full'}>
              {attachments.map(attachment => (
                <EmailDraftAttachment
                  key={attachment.fileId}
                  attachment={attachment}
                  removeAttachment={removeAttachment}
                />
              ))}
            </Flex>
          </EmailDraftLabelToData>
        </Box>
      )}
    </Stack>
  );
};

const EmailDraftTo = ({
  emailDraft,
  updateEmailDraft
}: {
  emailDraft: { to: string[] };
  updateEmailDraft: (data: UpdateEmailDraftData) => void;
}) => {
  const { _ } = useLingui();

  const [tos, setTos] = React.useState<string[]>(emailDraft.to);

  const updateTos = (tos: string[]) => {
    setTos(tos);
    updateEmailDraft({ to: tos });
  };

  React.useEffect(() => {
    setTos(emailDraft.to);
  }, [emailDraft.to]);

  return (
    <EmailDraftLabelToData
      label={<Trans>To</Trans>}
      hoverCardDescription={
        <Trans>
          The email address(es) that should receive the email. Each recipient receives a separate
          email.
        </Trans>
      }
    >
      <Flex gap={2} flexWrap={'wrap'} w={'full'}>
        {tos.map((to, index) => (
          <Tag
            key={index}
            closable
            onClose={() => updateTos(tos.filter(t => t !== to))}
            variant={'outline'}
            colorPalette={'primary'}
          >
            {to}
          </Tag>
        ))}
        <AddButtonWithInput
          label={<Trans>To</Trans>}
          placeholder={_(msg`Enter email address`)}
          onAdd={emails => updateTos(removeDuplicates([...tos, ...emails], email => email))}
        />
      </Flex>
    </EmailDraftLabelToData>
  );
};
const EmailDraftFrom = ({
  emailDraft,
  updateEmailDraft
}: {
  emailDraft: { from: string };
  updateEmailDraft: (data: UpdateEmailDraftData) => void;
}) => {
  return (
    <EmailDraftLabelToData label={<Trans>From</Trans>}>
      <Tag variant={'outline'} colorPalette={'primary'}>
        {emailDraft.from}
      </Tag>
    </EmailDraftLabelToData>
  );
};

const EmailDraftReplyTo = ({
  emailDraft,
  updateEmailDraft
}: {
  emailDraft: { replyTo: string[] };
  updateEmailDraft: (data: UpdateEmailDraftData) => void;
}) => {
  const { _ } = useLingui();

  const [replyTos, setReplyTos] = React.useState<string[]>(emailDraft.replyTo);

  const updateReplyTos = (replyTos: string[]) => {
    setReplyTos(replyTos);
    updateEmailDraft({ replyTo: replyTos });
  };

  React.useEffect(() => {
    setReplyTos(emailDraft.replyTo);
  }, [emailDraft.replyTo]);

  return (
    <EmailDraftLabelToData
      label={<Trans>Reply To</Trans>}
      hoverCardDescription={
        <Trans>The email address(es) that should receive a reply to the email.</Trans>
      }
    >
      <Flex gap={2} flexWrap={'wrap'} w={'full'}>
        {replyTos.map((replyTo, index) => (
          <Tag
            key={index}
            closable
            onClose={() => updateReplyTos(replyTos.filter(r => r !== replyTo))}
            variant={'outline'}
            colorPalette={'primary'}
          >
            {replyTo}
          </Tag>
        ))}
        <AddButtonWithInput
          label={<Trans>Reply To</Trans>}
          placeholder={_(msg`Enter email address`)}
          onAdd={emails =>
            updateReplyTos(removeDuplicates([...replyTos, ...emails], email => email))
          }
        />
      </Flex>
    </EmailDraftLabelToData>
  );
};

const EmailDraftCc = ({
  emailDraft,
  updateEmailDraft
}: {
  emailDraft: { cc: string[] };
  updateEmailDraft: (data: UpdateEmailDraftData) => void;
}) => {
  const { _ } = useLingui();

  const [ccs, setCcs] = React.useState<string[]>(emailDraft.cc);

  const updateCcs = (ccs: string[]) => {
    setCcs(ccs);
    updateEmailDraft({ cc: ccs });
  };

  React.useEffect(() => {
    setCcs(emailDraft.cc);
  }, [emailDraft.cc]);

  return (
    <EmailDraftLabelToData label={<Trans>CC</Trans>}>
      <Flex gap={2} flexWrap={'wrap'} w={'full'}>
        {ccs.map((cc, index) => (
          <Tag
            key={index}
            closable
            onClose={() => updateCcs(ccs.filter(c => c !== cc))}
            variant={'outline'}
            colorPalette={'primary'}
          >
            {cc}
          </Tag>
        ))}
        <AddButtonWithInput
          label={<Trans>CC</Trans>}
          placeholder={_(msg`Enter email address`)}
          onAdd={emails => updateCcs(removeDuplicates([...ccs, ...emails], email => email))}
        />
      </Flex>
    </EmailDraftLabelToData>
  );
};

const EmailDraftBcc = ({
  emailDraft,
  updateEmailDraft
}: {
  emailDraft: { bcc: string[] };
  updateEmailDraft: (data: UpdateEmailDraftData) => void;
}) => {
  const { _ } = useLingui();

  const [bccs, setBccs] = React.useState<string[]>(emailDraft.bcc);

  const updateBccs = (bccs: string[]) => {
    setBccs(bccs);
    updateEmailDraft({ bcc: bccs });
  };

  React.useEffect(() => {
    setBccs(emailDraft.bcc);
  }, [emailDraft.bcc]);

  return (
    <EmailDraftLabelToData label={<Trans>BCC</Trans>}>
      <Flex gap={2} flexWrap={'wrap'} w={'full'}>
        {bccs.map((bcc, index) => (
          <Tag
            key={index}
            closable
            onClose={() => updateBccs(bccs.filter(b => b !== bcc))}
            variant={'outline'}
            colorPalette={'primary'}
          >
            {bcc}
          </Tag>
        ))}
        <AddButtonWithInput
          label={<Trans>BCC</Trans>}
          placeholder={_(msg`Enter email address`)}
          onAdd={emails => updateBccs(removeDuplicates([...bccs, ...emails], email => email))}
        />
      </Flex>
    </EmailDraftLabelToData>
  );
};

const EmailDraftSubject = ({
  emailDraft,
  updateEmailDraft
}: {
  emailDraft: { subject: string };
  updateEmailDraft: (data: UpdateEmailDraftData) => void;
}) => {
  const { _ } = useLingui();

  const [subject, setSubject] = React.useState(emailDraft.subject);

  const updateSubject = (subject: string) => {
    setSubject(subject);
    updateEmailDraft({ subject });
  };

  React.useEffect(() => {
    setSubject(emailDraft.subject);
  }, [emailDraft.subject]);

  return (
    <EmailDraftLabelToData label={<Trans>Subject</Trans>} hasBorderBottom={false}>
      <Input
        variant={'flushed'}
        value={subject}
        onChange={e => updateSubject(e.target.value)}
        ml={-2}
        pl={2}
        placeholder={_(msg`Subject`)}
      />
    </EmailDraftLabelToData>
  );
};

const EmailDraftAttachment = ({
  attachment,
  removeAttachment
}: {
  attachment: { fileId: string; name: string; extension: string };
  removeAttachment: (fileId: string) => void;
}) => {
  return (
    <Flex
      key={attachment.fileId}
      gap={2}
      alignItems={'center'}
      rounded={'md'}
      pl={4}
      borderWidth={1}
      shadow={'xs'}
      fontSize={'sm'}
    >
      <FileIcon boxSize={4} />
      <Text>{getFullFileName(attachment.name, attachment.extension)}</Text>
      <Box borderLeftWidth={1}>
        <MenuRoot>
          <MenuTrigger asChild>
            <IconButton size={'xs'} variant={'ghost'} color={'fg.muted'} roundedLeft={'none'}>
              <ChevronDownIcon boxSize={3.5} />
            </IconButton>
          </MenuTrigger>
          <MenuContent>
            <MenuItem
              value={'download'}
              onClick={() => downloadFile({ fileId: attachment.fileId })}
            >
              <Trans>Download</Trans>
            </MenuItem>
            <MenuItem
              value="delete"
              color="fg.error"
              _hover={{ bg: 'bg.error', color: 'fg.error' }}
              onClick={() => removeAttachment(attachment.fileId)}
            >
              <Trans>Remove</Trans>
            </MenuItem>
          </MenuContent>
        </MenuRoot>
      </Box>
    </Flex>
  );
};

const EmailDraftBody = ({
  emailDraft,
  updateEmailDraft
}: {
  emailDraft: { body: string };
  updateEmailDraft: (data: UpdateEmailDraftData) => void;
}) => {
  const [content, setContent] = React.useState(emailDraft.body);
  const { _ } = useLingui();

  const handleContentChange = (content: string) => {
    setContent(content);
    updateEmailDraft({ body: content });
  };

  React.useEffect(() => {
    setContent(emailDraft.body);
  }, [emailDraft.body]);

  return (
    <DocumentEditor
      content={content}
      setContent={handleContentChange}
      placeholder={_(msg`Write your email here...`)}
    />
  );
};

const EmailDraftLabelToData = ({
  label,
  children,
  hoverCardDescription,
  hasBorderBottom = true
}: {
  label: React.ReactNode;
  children: React.ReactNode;
  hasBorderBottom?: boolean;
  hoverCardDescription?: React.ReactNode;
}) => {
  return (
    <Flex gap={6} alignItems={'center'} w={'full'} mx={6}>
      <Flex w={'200px'} fontSize={'sm'} color={'fg.muted'} gap={4} alignItems={'center'}>
        {label}
        {hoverCardDescription && <InfoHoverCard description={hoverCardDescription} />}
      </Flex>
      <Flex
        alignItems={'center'}
        minH={'35px'}
        w={'full'}
        maxW={'880px'}
        borderBottomWidth={hasBorderBottom ? 1 : 0}
        pl={2}
      >
        {children}
      </Flex>
    </Flex>
  );
};

const AddButtonWithInput = ({
  label,
  placeholder,
  onAdd
}: {
  label: React.ReactNode;
  placeholder: string;
  onAdd: (emails: string[]) => void;
}) => {
  const { isOpen, onOpenChange } = useDisclosure();
  const { _ } = useLingui();
  const form = useZodForm({
    schema: z.object({
      value: z.string()
    })
  });
  useResetForm({
    form,
    isOpen,
    values: {
      value: ''
    }
  });

  const parseEmails = (input: string): string[] => {
    // Match email patterns including those with display names like "Name" <email@example.com>
    const emailRegex =
      /(?:"?([^"]*)"?\s)?(?:<)?([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*)(?:>)?/g;
    const emails: string[] = [];

    // Split by common separators first
    const parts = input.split(/[,;]\s*/);

    parts.forEach(part => {
      let match;
      while ((match = emailRegex.exec(part)) !== null) {
        if (match[2]) {
          emails.push(match[2]);
        }
      }
    });

    return emails.filter(email => email.includes('@'));
  };

  const onSubmit = form.handleSubmit(data => {
    const emails = parseEmails(data.value);
    if (emails.length > 0) {
      onAdd(emails);
      onOpenChange({ open: false });
    }
  });

  return (
    <PopoverRoot open={isOpen} onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>
        <Button variant={'ghost'} size={'xs'} h={'20px'} ml={'auto'}>
          <Trans>Add</Trans>
        </Button>
      </PopoverTrigger>
      <PopoverContent minW={'480px'}>
        <Form onSubmit={onSubmit}>
          <PopoverBody>
            <Stack gap={2.5}>
              <PopoverTitle fontWeight="medium">{label}</PopoverTitle>
              <TextInput
                form={form}
                name={'value'}
                placeholder={placeholder}
                size={'sm'}
                helperText={
                  <Trans>
                    You can enter multiple emails separated by commas or semicolons. Format like
                    "Name" &lt;email@example.com&gt; is also supported.
                  </Trans>
                }
              />
              <Button mr={'auto'} colorPalette={'primary'} size={'xs'} type={'submit'}>
                <Trans>Add</Trans>
              </Button>
            </Stack>
          </PopoverBody>
        </Form>
      </PopoverContent>
    </PopoverRoot>
  );
};

const InfoHoverCard = ({ description }: { description: React.ReactNode }) => {
  return (
    <HoverCardRoot size="sm">
      <HoverCardTrigger asChild>
        <IconButton size={'xs'} variant={'ghost'} color={'fg.muted'}>
          <InfoIcon boxSize={3.5} />
        </IconButton>
      </HoverCardTrigger>
      <HoverCardContent>
        <HoverCardArrow />
        <Text>{description}</Text>
      </HoverCardContent>
    </HoverCardRoot>
  );
};

type EmailStatisticsProps = {
  emails: {
    status: EmailStatus;
    id: string;
    to: string[];
    subject: string;
    sentOn: Date | null;
  }[];
};

const EmailStatistics = ({ emails }: EmailStatisticsProps) => {
  if (emails.length === 0) {
    return null;
  }

  return (
    <PopoverRoot size="sm">
      <PopoverTrigger asChild>
        <Button variant={'surface'} size={'xs'}>
          <Trans>Statistics</Trans>
        </Button>
      </PopoverTrigger>
      <PopoverContent minW={'360px'} py={2}>
        <Stack gap={2} separator={<Separator />}>
          {emails.map(email => (
            <EmailStatisticsItem key={email.id} email={email} />
          ))}
        </Stack>
      </PopoverContent>
    </PopoverRoot>
  );
};

const EmailStatisticsItem = ({ email }: { email: EmailStatisticsProps['emails'][number] }) => {
  const { localeCode } = useAuthUser();
  const { _ } = useLingui();

  return (
    <Stack gap={1} px={4} py={1}>
      <Flex alignItems={'center'} gap={2}>
        <Text fontSize={'sm'} fontWeight={'medium'} lineClamp={1} flex={1}>
          {email.subject}
        </Text>
        <Tag size={'sm'} colorPalette={COLOR_MAP[EMAIL_STATUSES[email.status].color].palette}>
          {EMAIL_STATUSES[email.status].getLabel(_)}
        </Tag>
      </Flex>
      <Flex justifyContent={'space-between'} gap={2}>
        <Text fontSize={'xs'} color={'fg.muted'}>
          {email.to.join(', ')}
        </Text>
        {email.sentOn && (
          <Text fontSize={'xs'} color={'fg.muted'}>
            {formatDate(email.sentOn, localeCode)}
          </Text>
        )}
      </Flex>
    </Stack>
  );
};
