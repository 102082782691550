'use client';

import { Menu } from '@company/ui/components';
import { ArrowDownIcon, ArrowUpIcon, TrashIcon } from '@company/ui/icons';
import { useTableStore } from '@components/table/stores/table-store';
import { Trans } from '@lingui/react/macro';
import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import { OpenedCellContextMenu } from '../types';
import { typeidUnboxed } from 'typeid-js';

interface GridCellContextMenuProps {
  openedCellContextMenu: OpenedCellContextMenu | null;
  onCloseCellContextMenu: () => void;
}

export const GridCellContextMenu = ({
  openedCellContextMenu,
  onCloseCellContextMenu
}: GridCellContextMenuProps) => {
  const { deleteRows, addRow, activeView } = useTableStore(
    useShallow(state => ({
      deleteRows: state.deleteRows,
      addRow: state.addRow,
      activeView: state.table.activeView
    }))
  );

  const onOpenChange = React.useCallback(
    (details: { open: boolean }) => {
      if (!details.open) {
        onCloseCellContextMenu();
      }
    },
    [onCloseCellContextMenu]
  );

  if (
    openedCellContextMenu == null ||
    (!activeView.config.canAddRows && !activeView.config.canDeleteRows)
  ) {
    return null;
  }

  return (
    <Menu.Root
      open={true}
      onOpenChange={onOpenChange}
      positioning={{
        placement: 'bottom-end',
        gutter: 10,
        getAnchorRect: () => {
          return {
            x: openedCellContextMenu.position.x,
            y: openedCellContextMenu.position.y,
            width: 0,
            height: 0
          };
        }
      }}
    >
      <Menu.Positioner>
        <Menu.Content>
          {activeView.config.canAddRows && (
            <>
              <Menu.Item
                value="insert-row-above"
                onClick={() =>
                  addRow({
                    position: 'above',
                    referenceRowId: openedCellContextMenu.rowId,
                    row: {
                      id: typeidUnboxed('row')
                    }
                  })
                }
              >
                <ArrowUpIcon />
                <Trans>Insert row above</Trans>
              </Menu.Item>
              <Menu.Item
                value="insert-row-below"
                onClick={() =>
                  addRow({
                    referenceRowId: openedCellContextMenu.rowId,
                    position: 'below',
                    row: {
                      id: typeidUnboxed('row')
                    }
                  })
                }
              >
                <ArrowDownIcon />
                <Trans>Insert row below</Trans>
              </Menu.Item>
            </>
          )}

          {activeView.config.canDeleteRows && (
            <>
              <Menu.Separator />
              <Menu.Item
                value="delete-row"
                color="fg.error"
                _hover={{ bg: 'bg.error', color: 'fg.error' }}
                onClick={() => deleteRows([{ id: openedCellContextMenu.rowId }])}
              >
                <TrashIcon />
                <Trans>Delete row</Trans>
              </Menu.Item>
            </>
          )}
        </Menu.Content>
      </Menu.Positioner>
    </Menu.Root>
  );
};
