'use client';

import { Box, Flex, IconButton, Input } from '@company/ui/components';
import { XIcon } from '@company/ui/icons';
import { msg } from '@lingui/core/macro';
import { useLingui } from '@lingui/react';
import debounce from 'lodash/debounce';
import React from 'react';
import { useGrid } from '../provider';

export const QuickFilter = () => {
  const { _ } = useLingui();
  const { quickFilterText, updateQuickFilterText, openedChildTableDialog, openedLinkedRowDialog } =
    useGrid();

  const [isOpen, setIsOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState(quickFilterText);

  const debouncedUpdateFilter = React.useMemo(
    () =>
      debounce((value: string) => {
        updateQuickFilterText(value);
      }, 300),
    [updateQuickFilterText]
  );

  const onClose = () => {
    updateQuickFilterText('');
    setInputValue('');
    setIsOpen(false);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    debouncedUpdateFilter(value);
  };

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (openedChildTableDialog || openedLinkedRowDialog) {
        // If either dialog is open, don't allow the search to open
        return;
      }

      if (event.key === 'Escape' && isOpen) {
        onClose();
        return;
      }

      if ((event.metaKey || event.ctrlKey) && event.key === 'f') {
        event.preventDefault();

        if (isOpen) {
          onClose();
        } else {
          setIsOpen(true);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      debouncedUpdateFilter.cancel();
    };
  }, [isOpen, openedChildTableDialog, openedLinkedRowDialog, debouncedUpdateFilter]);

  if (!isOpen) {
    return null;
  }

  return (
    <Box
      position={'absolute'}
      top={1}
      right={4}
      w={'310px'}
      px={3}
      py={2}
      bgColor={'white'}
      shadow={'xs'}
      rounded={'md'}
    >
      <Flex alignItems={'center'} gap={1} w={'100%'}>
        <Input
          size={'sm'}
          placeholder={_(msg`Search...`)}
          variant={'flushed'}
          autoFocus
          onChange={onChange}
          value={inputValue}
        />
        <IconButton size={'xs'} variant={'ghost'} onClick={onClose}>
          <XIcon />
        </IconButton>
      </Flex>
    </Box>
  );
};
