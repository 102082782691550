'use client';

import { Box, Button, Flex, Text } from '@company/ui/components';
import { DiffIcon, FilterXIcon } from '@company/ui/icons';
import { Trans } from '@lingui/react/macro';
import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useTableStore } from '../stores/table-store';
import { TableHeaderProps } from '../types';
import { ExportTableButton } from './export-table-button';
import { ShareButton } from './share-button';

interface ITableHeaderProps {
  header?: TableHeaderProps;
}

export const TableHeader = ({ header }: ITableHeaderProps) => {
  const table = useTableStore(
    useShallow(state => ({
      changeProposalId: state.table.changeProposalId,
      name: state.table.name
    }))
  );

  return (
    <Box w={'full'} h={'47px'} px={4} bgColor={'Background'} roundedTop={'lg'}>
      <Flex h={'full'} alignItems="center" gap={4} justify={'space-between'}>
        <Box h={'full'}>
          <Flex h={'full'} alignItems={'center'} gap={1.5}>
            {header?.leftAddon}
            <Text fontSize={'sm'} fontWeight={'medium'}>
              {table.name}
            </Text>
          </Flex>
        </Box>
        <Box h={'full'}>
          <Flex h={'full'} alignItems={'center'} gap={1.5}>
            {table.changeProposalId !== null && <ChangeProposalActions />}
            <ShareButton />
            <ExportTableButton />
            {header?.rightAddon}
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

const ChangeProposalActions = () => {
  const { isViewingChangeProposalDiff, hideChangeProposalDiff, viewChangeProposalDiff } =
    useTableStore(
      useShallow(state => ({
        isViewingChangeProposalDiff: state.isViewingChangeProposalDiff,
        hideChangeProposalDiff: state.hideChangeProposalDiff,
        viewChangeProposalDiff: state.viewChangeProposalDiff
      }))
    );

  const onViewOnlyProposedChangeToggle = React.useCallback(() => {
    if (isViewingChangeProposalDiff) {
      hideChangeProposalDiff();
    } else {
      viewChangeProposalDiff();
    }
  }, [isViewingChangeProposalDiff, hideChangeProposalDiff, viewChangeProposalDiff]);

  return (
    <>
      <Button onClick={onViewOnlyProposedChangeToggle} size={'xs'} variant={'surface'}>
        {isViewingChangeProposalDiff ? <FilterXIcon /> : <DiffIcon />}
        {isViewingChangeProposalDiff ? (
          <Trans>Show All Rows</Trans>
        ) : (
          <Trans>Show Proposed Changes Only</Trans>
        )}
      </Button>
    </>
  );
};
