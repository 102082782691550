import { CellStyleFunc, ColDef } from '@ag-grid-community/core';
import {
  CustomCellEditorProps,
  CustomCellRendererProps,
  CustomHeaderProps
} from '@ag-grid-community/react';
import { rowValueToString } from '@company/common/lib';
import { FieldType } from '@company/common/types';
import { Checkbox } from '@company/ui/components';
import {
  AsteriskIcon,
  BaselineIcon,
  BoxIcon,
  CalendarIcon,
  CheckIcon,
  CircleChevronDownIcon,
  ClockIcon,
  FileIcon,
  HashIcon,
  LetterTextIcon,
  ListIcon
} from '@company/ui/icons';
import { useTableStore } from '@components/table/stores/table-store';
import { UpdateTableRow, ViewField } from '@components/table/types';
import { TableRowDtoUi } from '@typings/table';
import { useShallow } from 'zustand/react/shallow';
import { CellEditor, CellRenderer } from './cells';
import { ActionCell } from './cells/action';
import { SingleSelectCellEditor } from './cells/single-select';

const addColor = 'var(--chakra-colors-green-200)';
const deleteColor = 'var(--chakra-colors-red-200)';

const cellStyle: CellStyleFunc<TableRowDtoUi, any> = params => {
  if (params.data && params.data.proposedChange) {
    const proposedChange = params.data.proposedChange;
    if (proposedChange.type === 'DELETE') {
      return {
        backgroundColor: deleteColor
      };
    } else if (proposedChange.type === 'INSERT') {
      return {
        backgroundColor: addColor
      };
    } else if (proposedChange.type === 'UPDATE') {
      const newValue = rowValueToString(params.data[params.column!.getColId()]);
      const oldValue = rowValueToString(proposedChange.oldValue?.[params.column!.getColId()]);

      if (newValue !== oldValue) {
        if (newValue === null || newValue === '') {
          return {
            backgroundColor: deleteColor
          };
        } else {
          return {
            backgroundColor: addColor
          };
        }
      }
    }
  } else {
    return {
      backgroundColor: 'transparent'
    };
  }
};

export const getColumnDef = (
  field: ViewField,
  updateRows: (rows: UpdateTableRow[]) => void
): ColDef<TableRowDtoUi> => {
  const commonColDef: ColDef<TableRowDtoUi> = {
    colId: field.id,
    headerName: field.name,
    width: field.width,
    editable: field.isEditable,
    headerComponentParams: {
      icon: fieldTypeToIcon[field.type],
      displayName: field.name
    },
    valueGetter: params => {
      const { data } = params;
      if (!data) {
        return null;
      }
      return data[field.id];
    },
    valueSetter: params => {
      const { data } = params;
      if (!data) {
        return false;
      }

      if (field.type === 'LINKED_ROW' && params.newValue === null) {
        data[field.id] = {
          linkedRows: [],
          recommendationStatus: 'COMPLETED'
        };
      } else {
        data[field.id] = params.newValue;
      }

      return true;
    },
    cellRenderer: (params: CustomCellRendererProps<TableRowDtoUi>) => {
      if (!params.data) {
        return null;
      }
      return <CellRenderer field={field} row={params.data} {...params} />;
    },
    cellEditor: ['LINKED_ROW', 'SINGLE_SELECT', 'MULTI_SELECT'].includes(field.type)
      ? (params: CustomCellEditorProps<TableRowDtoUi>) => {
          return <CellEditor field={field} row={params.data!} {...params} />;
        }
      : undefined,
    onCellValueChanged: params => {
      void updateRows([
        {
          id: params.data.id,
          [field.id]: params.data[field.id] ?? null
        }
      ]);
    },
    cellStyle
  };

  const fieldTypeToColDef: Record<FieldType, ColDef> = {
    LONG_TEXT: {},
    SHORT_TEXT: {},
    NUMBER: {},
    SINGLE_SELECT: {},
    MULTI_SELECT: {},
    DATE: {},
    DATE_TIME: {},
    CHECKBOX: {},
    FILE_ATTACHMENT: {},
    LINKED_ROW: {},
    OBJECT: {}
  };

  return {
    ...commonColDef,
    ...fieldTypeToColDef[field.type]
  };
};

export const expandColumnDef: ColDef<TableRowDtoUi> = {
  colId: 'expand',
  headerComponent: (params: CustomHeaderProps<TableRowDtoUi>) => {
    const activeView = useTableStore(useShallow(state => state.table.activeView));
    if (!activeView.config.canDeleteRows) {
      return null;
    }
    return (
      <>
        <Checkbox
          variant={'solid'}
          colorPalette={'blue'}
          size={'xs'}
          checked={params.api.getSelectedNodes().length > 0}
          onCheckedChange={() => {
            if (params.api.getSelectedNodes().length > 0) {
              params.api.deselectAll();
            } else {
              params.api.selectAll();
            }
            params.api.redrawRows();
            params.api.refreshHeader();
          }}
        />
      </>
    );
  },
  width: 75,
  cellRenderer: (params: CustomCellEditorProps<TableRowDtoUi>) => {
    if (!params.data) {
      return null;
    }

    return (
      <ActionCell
        row={params.data}
        rowIndex={params.node.rowIndex}
        isSelected={params.node.isSelected() ?? false}
        setSelected={isSelected => {
          params.node.setSelected(isSelected);
          params.api.redrawRows({ rowNodes: [params.node] });
          params.api.refreshHeader();
        }}
      />
    );
  },
  cellStyle
};

const fieldTypeToIcon: Record<FieldType, React.ElementType> = {
  LONG_TEXT: LetterTextIcon,
  SHORT_TEXT: BaselineIcon,
  NUMBER: HashIcon,
  SINGLE_SELECT: CircleChevronDownIcon,
  MULTI_SELECT: ListIcon,
  DATE: CalendarIcon,
  DATE_TIME: ClockIcon,
  CHECKBOX: CheckIcon,
  FILE_ATTACHMENT: FileIcon,
  LINKED_ROW: AsteriskIcon,
  OBJECT: BoxIcon
};
