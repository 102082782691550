'use client';

import { removeDuplicates } from '@company/common/lib';
import { FieldDto, TableRowDto, TableRowFilter } from '@company/common/types';
import { Center, Spinner, Text } from '@company/ui/components';
import { Trans } from '@lingui/react/macro';
import { trpc } from '@server/trpc';
import { FieldToLinkedRowRecommendationData, SearchableTableDto, TableDtoUi } from '@typings/table';
import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import { ErrorBoundary } from '../error-boundry';
import { TableHeader } from './header';
import { useSubscribeToLinkedRowChanges, useSubscribeToProposedChanges } from './hooks';
import { ChildTableProvider } from './providers/child-table-provider';
import { LinkedRowTableProvider } from './providers/linked-row-provider';
import { TableQueryStoreProvider, useTableQueryStore } from './stores/table-query-store';
import { TableStoreProvider, useTableStore } from './stores/table-store';
import { TableHeaderProps } from './types';
import { GridView } from './views';

interface TableConfig {}

type TableProps = {
  changeProposalId?: string | null;
  config?: TableConfig;
  header?: TableHeaderProps;
} & (
  | {
      tableId: string;
      viewId?: string;
      where?: {
        row?: TableRowFilter;
      };
    }
  | { table: TableDtoUi }
);

export const Table = ({ changeProposalId, config, header, ...tableProps }: TableProps) => {
  const isTableDto = 'table' in tableProps;
  const { tableId, viewId, where } = isTableDto
    ? { tableId: undefined, viewId: undefined, where: undefined }
    : tableProps;
  const queryParams = React.useMemo(
    () => ({
      tableId: tableId!,
      viewId,
      changeProposalId,
      where: {
        row: where?.row
      }
    }),
    [tableId, viewId, changeProposalId, where]
  );

  const [table, setTable] = React.useState<TableDtoUi | null>(isTableDto ? tableProps.table : null);
  const [isLoadingTable, setIsLoadingTable] = React.useState(table === null);

  const trpcUtils = trpc.useUtils();
  const { data: childTable } = trpc.table.getById.useQuery(
    {
      tableId: table?.childTableId!,
      viewId: null,
      changeProposalId: null,
      where: {}
    },
    {
      enabled: !!table?.childTableId
    }
  );

  const isLinkedRowFieldInActiveView = (field: FieldDto) =>
    field.type === 'LINKED_ROW' &&
    table?.activeView.state.fields.map(field => field.id).includes(field.id);

  const linkedRowFields = table?.fields.filter(isLinkedRowFieldInActiveView);

  const { data: linkedRowTables } = trpc.table.getLinkedRowTables.useQuery(
    {
      tableId: table?.id ?? '',
      fieldIds: linkedRowFields?.map(field => field.id) ?? []
    },
    { enabled: !!table }
  );
  const { data: searchableTables } = trpc.table.getSearchableTables.useQuery(
    {
      tableIds: linkedRowTables?.map(table => table.id) ?? []
    },
    { enabled: !!linkedRowTables }
  );
  const { data: fieldToLinkedRowRecommendationData } =
    trpc.table.linkedRow.getMinimalRecommendationInfoForFields.useQuery(
      {
        fieldIds: linkedRowFields?.map(field => field.id) ?? []
      },
      { enabled: !!linkedRowFields }
    );

  const fetchTable = async () => {
    const table = await trpcUtils.table.getById.fetch(queryParams);
    setTable(table);
    setIsLoadingTable(false);
    return table;
  };

  const addRowsToLinkedRowTables = ({
    rows,
    tableId
  }: {
    rows: TableRowDto[];
    tableId: string;
  }) => {
    trpcUtils.table.getLinkedRowTables.setData(
      { tableId: tableId, fieldIds: linkedRowFields?.map(field => field.id) ?? [] },
      prev => {
        if (!prev) {
          return prev;
        }
        return prev.map(table => {
          if (table.id === tableId) {
            return {
              ...table,
              rows: removeDuplicates(
                [
                  ...table.rows,
                  ...rows.map(row => ({
                    ...row,
                    isLeafRow: true,
                    parentRowIdPath: []
                  }))
                ],
                row => row.id,
                { keepLast: true }
              )
            };
          }
          return table;
        });
      }
    );
  };

  React.useEffect(() => {
    if (!isTableDto) {
      void fetchTable();
    }
  }, [isTableDto, fetchTable]);

  React.useEffect(() => {
    if (table && !isTableDto) {
      setIsLoadingTable(true);
      setTable(null);
      void fetchTable();
    }
  }, [queryParams]);

  React.useEffect(() => {
    if (isTableDto) {
      setTable(tableProps.table);
    }
  }, [isTableDto, tableProps]);

  if (isLoadingTable) {
    return <LoadingTable />;
  }

  if (!table) {
    return <Trans>Table not found</Trans>;
  }

  return (
    <ErrorBoundary>
      <TableStoreProvider initialValue={{ table, fetchTable }}>
        <TableQueryStoreProvider
          initialValue={{
            tables: searchableTables ? searchableTables : []
          }}
        >
          <TableInner
            table={table}
            childTable={childTable}
            linkedRowTables={linkedRowTables}
            fieldToLinkedRowRecommendationData={fieldToLinkedRowRecommendationData}
            searchableTables={searchableTables}
            config={config}
            header={header}
            addRowsToLinkedRowTables={addRowsToLinkedRowTables}
          />
        </TableQueryStoreProvider>
      </TableStoreProvider>
    </ErrorBoundary>
  );
};

interface TableInnerProps {
  table: TableDtoUi;
  childTable: TableDtoUi | null | undefined;
  linkedRowTables: TableDtoUi[] | undefined;
  addRowsToLinkedRowTables: (data: { rows: TableRowDto[]; tableId: string }) => void;
  searchableTables: SearchableTableDto[] | undefined;
  fieldToLinkedRowRecommendationData: FieldToLinkedRowRecommendationData | undefined;
  config?: TableConfig;
  header?: TableHeaderProps;
}

const TableInner = ({
  table,
  childTable,
  linkedRowTables,
  searchableTables,
  fieldToLinkedRowRecommendationData,
  config,
  header,
  addRowsToLinkedRowTables
}: TableInnerProps) => {
  const { updateRows, updateTable } = useTableStore(
    useShallow(state => ({
      table: state.table,
      updateRows: state.updateRows,
      updateTable: state.updateTable
    }))
  );
  const { updateTables } = useTableQueryStore(
    useShallow(state => ({
      updateTables: state.updateTables
    }))
  );

  useSubscribeToLinkedRowChanges({
    tableId: table.id,
    updateRows,
    addRowsToLinkedRowTables
  });

  useSubscribeToProposedChanges({
    changeProposalId: table.changeProposalId,
    addProposedChangesToTableRows: () => {}
  });

  React.useEffect(() => {
    if (searchableTables) {
      updateTables(searchableTables);
    }
  }, [searchableTables, updateTables]);

  React.useEffect(() => {
    if (table) {
      updateTable(table);
    }
  }, [table, updateTable]);

  return (
    <ChildTableProvider childTable={childTable}>
      <LinkedRowTableProvider
        linkedRowTables={linkedRowTables}
        fieldToLinkedRowRecommendationData={fieldToLinkedRowRecommendationData}
      >
        <View config={config ?? {}} header={header} />
      </LinkedRowTableProvider>
    </ChildTableProvider>
  );
};

const LoadingTable = () => {
  return (
    <Center h={'full'} gap={4}>
      <Spinner />
      <Text>
        <Trans>Loading data...</Trans>
      </Text>
    </Center>
  );
};

interface ViewProps {
  config: TableConfig;
  header?: TableHeaderProps;
}

export const View = (props: ViewProps) => {
  const activeView = useTableStore(useShallow(state => state.table.activeView));
  const viewType = activeView?.type;

  const header = React.useMemo(() => <TableHeader header={props.header} />, [props.header]);

  const getViewComponent = () => {
    switch (viewType) {
      case 'GRID':
        return <GridView header={header} />;
      default:
        return null;
    }
  };

  return getViewComponent();
};
