import { formatNumber } from '@company/common/lib';
import { useAuthUser } from '@providers/auth-user-provider';
import React from 'react';
import { CellRendererProps } from './types';

export const NumberCell: React.FC<CellRendererProps> = props => {
  const { localeCode } = useAuthUser();
  const value = props.row[props.field.id]! as number | null | undefined;

  const formattedValue = React.useMemo(() => {
    return formatNumber(value, {
      fractionDigits: props.field.numberConfig?.decimalPlaces,
      locale: localeCode
    });
  }, [value, props.field.numberConfig?.decimalPlaces, localeCode]);

  return <span>{formattedValue}</span>;
};
