import { Prose } from '@company/ui/components';
import { CellRendererProps } from './types';

export const LongTextCell: React.FC<CellRendererProps> = props => {
  const value = props.row[props.field.id]! as string | null | undefined;
  return (
    <Prose
      w={'full'}
      fontSize={'xs'}
      lineHeight={1.2}
      dangerouslySetInnerHTML={{ __html: removeStylesFromHtml(value ?? '') }}
      lineClamp={2}
    />
  );
};

const removeStylesFromHtml = (html: string) => {
  return html
    .replace(/<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/gi, '')
    .replace(/style="[^"]*"/gi, '');
};
