'use client';

import { Center, CloseButton, Dialog, Heading, Spinner, Text } from '@company/ui/components';
import { Table } from '@components/table';
import { useChildTable } from '@components/table/providers/child-table-provider';
import { Trans } from '@lingui/react/macro';
import React from 'react';
import { RowSwitcher } from '../common/dialog-row-switcher';
import { OpenedChildTableDialog } from '../types';

interface ChildTableDialogProps {
  openedChildTableDialog: OpenedChildTableDialog | null;
  onClose: () => void;
}

export const ChildTableDialog = ({ openedChildTableDialog, onClose }: ChildTableDialogProps) => {
  const { getChildTableByParentTableRowId } = useChildTable();
  const childTable = React.useMemo(
    () =>
      openedChildTableDialog
        ? getChildTableByParentTableRowId(openedChildTableDialog.parentTableRowId)
        : null,
    [openedChildTableDialog?.parentTableRowId, getChildTableByParentTableRowId]
  );

  return (
    <Dialog.Root
      open={!!openedChildTableDialog}
      onOpenChange={({ open }) => {
        if (!open) {
          onClose();
        }
      }}
      size={'cover'}
      scrollBehavior={'inside'}
      placement={'center'}
    >
      <Dialog.Backdrop />
      <Dialog.Positioner>
        <Dialog.Content my={0} maxH={'100%'}>
          <Dialog.Header display={'flex'} gap={1} alignItems={'center'} pb={0} pt={2}>
            {openedChildTableDialog && (
              <RowSwitcher type={'child-table'} rowId={openedChildTableDialog.parentTableRowId} />
            )}
            <Heading fontSize={'lg'} fontWeight={'semibold'}>
              {openedChildTableDialog?.title}
            </Heading>
            <Dialog.CloseTrigger asChild>
              <CloseButton size={'sm'} ml={'auto'} onClick={onClose} />
            </Dialog.CloseTrigger>
          </Dialog.Header>
          <Dialog.Body>
            {childTable ? (
              <Table table={childTable} />
            ) : (
              <Center h={'full'} gap={4} w={'full'}>
                <Spinner />
                <Text>
                  <Trans>Loading data...</Trans>
                </Text>
              </Center>
            )}
          </Dialog.Body>
        </Dialog.Content>
      </Dialog.Positioner>
    </Dialog.Root>
  );
};
