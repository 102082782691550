'use client';

import { Center, DocumentEditor, Spinner, Stack, Text } from '@company/ui/components';
import { useDebouncedCallback } from '@company/ui/hooks';
import { msg } from '@lingui/core/macro';
import { useLingui } from '@lingui/react';
import { Trans } from '@lingui/react/macro';
import { updateDocumentContentAction } from '@server/actions/document';
import { trpc } from '@server/trpc';
import React from 'react';
import { PieceOfInformationEditorHeader } from './header';

interface HeaderProps {
  leftAddon: React.ReactNode;
  rightAddon: React.ReactNode;
}

interface PieceOfInformationDocumentEditorProps {
  document: {
    id: string;
    title: string;
  };
  header: HeaderProps;
}

export const PieceOfInformationDocumentEditor = ({
  document,
  header
}: PieceOfInformationDocumentEditorProps) => {
  const trpcUtils = trpc.useUtils();
  const { _ } = useLingui();

  const { data, isPending } = trpc.document.getById.useQuery({ documentId: document.id });

  const debouncedDocumentContentUpdate = useDebouncedCallback(
    async (data: { documentId: string; content: string }) => {
      await updateDocumentContentAction(data);
    },
    1000,
    [document.id]
  );

  const updateDocumentContent = (content: string) => {
    const queryPath = trpcUtils.document.getById;
    queryPath.cancel();
    queryPath.setData({ documentId: document.id }, prev => {
      if (!prev) {
        return null;
      }

      return {
        ...prev,
        content
      };
    });

    void debouncedDocumentContentUpdate({ documentId: document.id, content });
  };

  return (
    <Stack gap={0} w={'full'} h={'full'} position={'relative'}>
      <PieceOfInformationEditorHeader
        title={document.title}
        leftAddon={header.leftAddon}
        rightAddon={header.rightAddon}
      />
      {isPending ? (
        <Center h={'full'} w={'full'} gap={4}>
          <Spinner />
          <Text>
            <Trans>Loading document...</Trans>
          </Text>
        </Center>
      ) : (
        <DocumentEditor
          content={data?.content ?? ''}
          autoFocus={true}
          setContent={updateDocumentContent}
          placeholder={_(msg`Write something...`)}
        />
      )}
    </Stack>
  );
};
