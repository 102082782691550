'use client';

import React, { createContext, useContext } from 'react';
import { ColDef } from '@ag-grid-community/core';
import { moveArrayItem, replaceHandlebars, rowValueToString } from '@company/common/lib';
import { logger } from '@company/common/logger';
import { FieldTypeEnum, TableRowDataType } from '@company/common/types';
import { useField } from '@components/table/hooks';
import { useTableStore } from '@components/table/stores/table-store';
import { TABLE_ROW_COLOR_LEVELS } from '@components/table/utils/styling';
import { TableRowDtoUi } from '@typings/table';
import { useShallow } from 'zustand/react/shallow';
import { expandColumnDef, getColumnDef } from './column-defs';
import { CustomGridColumnHeader } from './header';
import {
  AgGridComponents,
  OpenedAddOptionDialog,
  OpenedCellContextMenu,
  OpenedChildTableDialog,
  OpenedLinkedRowDialog
} from './types';

export interface GridState {
  columns: ColDef[];
  rows: TableRowDtoUi[];
  components: AgGridComponents;
  openedChildTableDialog: OpenedChildTableDialog | null;
  openedLinkedRowDialog: OpenedLinkedRowDialog | null;
  openedAddOptionDialog: OpenedAddOptionDialog | null;
  openedCellContextMenu: OpenedCellContextMenu | null;
  quickFilterText: string;
  onColumnResize: (fieldId: string, width: number) => void;
  onColumnMove: (fieldId: string, toIndex: number) => void;
  onOpenChildTable: (data: {
    childTableId: string;
    parentTableRowId: string;
    primaryFieldValue: TableRowDataType;
  }) => void;
  onOpenLinkedRowDialog: (data: { rowId: string; fieldId: string }) => void;
  onCloseChildTableDialog: () => void;
  onCloseLinkedRowDialog: () => void;
  onOpenAddOptionDialog: (data: {
    fieldId: string;
    rowId: string;
    onAddOption: (option: string) => void;
  }) => void;
  onCloseAddOptionDialog: () => void;
  onOpenCellContextMenu: (data: OpenedCellContextMenu) => void;
  onCloseCellContextMenu: () => void;
  getRowStyle: (rowId: string) => Partial<React.CSSProperties>;
  updateQuickFilterText: (text: string) => void;
  onClearCellValues: (cells: { rowId: string; fieldId: string }[]) => void;
  shouldCellBePreventedFromEditing: (fieldId: string) => boolean;
}

const GridContext = createContext<GridState | undefined>(undefined);

export const GridProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const { table, activeView, updateActiveViewState, getRowById, clearRowValues, updateRows } =
    useTableStore(
      useShallow(state => ({
        table: state.table,
        activeView: state.table.activeView,
        updateActiveViewState: state.updateActiveViewState,
        getRowById: state.getRowById,
        clearRowValues: state.clearRowValues,
        updateRows: state.updateRows
      }))
    );

  const { getFieldById, getPrimaryField } = useField();

  const [openedChildTableDialog, setOpenedChildTableDialog] =
    React.useState<OpenedChildTableDialog | null>(null);
  const [openedLinkedRowDialog, setOpenedLinkedRowDialog] =
    React.useState<OpenedLinkedRowDialog | null>(null);
  const [openedAddOptionDialog, setOpenedAddOptionDialog] =
    React.useState<OpenedAddOptionDialog | null>(null);
  const [isQuickFilterActive, setIsQuickFilterActive] = React.useState(false);
  const [quickFilterText, setQuickFilterText] = React.useState('');
  const [openedCellContextMenu, setOpenedCellContextMenu] =
    React.useState<OpenedCellContextMenu | null>(null);

  const columns = React.useMemo(
    () => [
      expandColumnDef,
      ...activeView.state.fields
        .filter(field => !field.isHidden)
        .map(viewField => {
          const field = getFieldById(viewField.id);
          return getColumnDef(
            {
              ...viewField,
              id: field.id,
              name: field.name,
              type: field.type,
              isPrimary: field.isPrimary,
              isEditable: field.isEditable,
              linkedRowConfig: field.linkedRowConfig,
              selectConfig: field.selectConfig,
              numberConfig: field.numberConfig,
              referenceId: field.referenceId
            },
            updateRows
          );
        })
    ],
    [activeView.state.fields, getFieldById]
  );

  const components = React.useMemo<AgGridComponents>(() => {
    return {
      agColumnHeader: CustomGridColumnHeader
    };
  }, []);

  const onColumnResize = React.useCallback(
    (fieldId: string, width: number) => {
      updateActiveViewState(
        {
          fields: activeView.state.fields.map(field => {
            if (field.id === fieldId) {
              return { ...field, width };
            }
            return field;
          })
        },
        {
          shouldSaveToDb: true
        }
      );
    },
    [activeView.state.fields, updateActiveViewState]
  );

  const onColumnMove = React.useCallback(
    (fieldId: string, toIndex: number) => {
      const toWithoutExpanded = toIndex - 1;
      const startIndex = activeView.state.fields.findIndex(field => field.id === fieldId);

      if (startIndex === toWithoutExpanded) {
        return;
      }

      const newFields = moveArrayItem(activeView.state.fields, startIndex, toWithoutExpanded);

      updateActiveViewState({
        fields: newFields
      });
    },
    [activeView.state.fields, updateActiveViewState]
  );

  const getRowTitle = React.useCallback(
    (row: TableRowDtoUi) => {
      const rowTitleTemplate = table.rowTitleTemplate;
      let rowTitle = rowValueToString(row[getPrimaryField().id]);
      if (rowTitleTemplate) {
        try {
          rowTitle = replaceHandlebars(rowTitleTemplate, row) ?? rowTitle;
        } catch (error) {
          logger.error(error);
        }
      }
      return rowTitle;
    },
    [table.rowTitleTemplate, getPrimaryField]
  );

  const onOpenLinkedRowDialog = React.useCallback(
    (data: { rowId: string; fieldId: string }) => {
      const field = getFieldById(data.fieldId);
      const row = getRowById(data.rowId);

      if (!field || !row) {
        return;
      }

      if (field.type === FieldTypeEnum.LINKED_ROW) {
        if (!row.isLeafRow) {
          return;
        }

        const linkedRowField = getFieldById(field.id);
        setOpenedLinkedRowDialog({
          tableId: table.id,
          fieldId: field.id,
          rowId: row.id,
          title: getRowTitle(row),
          linkedRowConfigType: linkedRowField.linkedRowConfig!.type
        });
      }
    },
    [table.id, getFieldById, getRowById, getRowTitle]
  );

  const onCloseLinkedRowDialog = React.useCallback(() => {
    setOpenedLinkedRowDialog(null);
  }, []);

  const onOpenChildTable = React.useCallback(
    (data: {
      childTableId: string;
      parentTableRowId: string;
      primaryFieldValue: TableRowDataType;
    }) => {
      setOpenedChildTableDialog({
        childTableId: data.childTableId,
        parentTableRowId: data.parentTableRowId,
        title: getRowTitle(getRowById(data.parentTableRowId))
      });
    },
    [getRowTitle, getRowById]
  );

  const onCloseChildTableDialog = React.useCallback(() => {
    setOpenedChildTableDialog(null);
  }, []);

  const onOpenAddOptionDialog = React.useCallback(
    (data: { fieldId: string; rowId: string; onAddOption: (option: string) => void }) => {
      setOpenedAddOptionDialog({
        tableId: table.id,
        fieldId: data.fieldId,
        rowId: data.rowId,
        onAddOption: data.onAddOption
      });
    },
    [table.id, setOpenedAddOptionDialog]
  );

  const onCloseAddOptionDialog = React.useCallback(() => {
    setOpenedAddOptionDialog(null);
  }, [setOpenedAddOptionDialog]);

  const onCloseCellContextMenu = React.useCallback(() => {
    setOpenedCellContextMenu(null);
  }, [setOpenedCellContextMenu]);

  const onOpenCellContextMenu = React.useCallback(
    (data: OpenedCellContextMenu) => {
      setOpenedCellContextMenu({
        rowId: data.rowId,
        fieldId: data.fieldId,
        position: {
          x: data.position.x,
          y: data.position.y
        }
      });
    },
    [setOpenedCellContextMenu]
  );

  const getRowStyle = React.useCallback(
    (rowId: string): Partial<React.CSSProperties> => {
      const row = getRowById(rowId);

      if (!row) {
        return {};
      }

      if (row.isLeafRow) {
        return {};
      }

      const rowDepth = row.parentRowIdPath.length - 1;

      return {
        backgroundColor: TABLE_ROW_COLOR_LEVELS[rowDepth]
      };
    },
    [getRowById]
  );

  const onClearCellValues = React.useCallback(
    (cells: { rowId: string; fieldId: string }[]) => {
      clearRowValues(cells);
    },
    [clearRowValues]
  );

  const shouldCellBePreventedFromEditing = React.useCallback(
    (fieldId: string) => {
      const field = getFieldById(fieldId);
      return field?.type === FieldTypeEnum.LINKED_ROW;
    },
    [getFieldById]
  );

  const gridState = React.useMemo(
    () => ({
      columns,
      rows: table.rows,
      components,
      openedChildTableDialog,
      openedLinkedRowDialog,
      openedAddOptionDialog,
      isQuickFilterActive,
      quickFilterText,
      openedCellContextMenu,
      onCloseCellContextMenu,
      onOpenCellContextMenu,
      onColumnResize,
      onColumnMove,
      onCloseChildTableDialog,
      onOpenLinkedRowDialog,
      onCloseLinkedRowDialog,
      getRowStyle,
      updateQuickFilterText: setQuickFilterText,
      updateIsQuickFilterActive: setIsQuickFilterActive,
      onOpenChildTable,
      onCloseAddOptionDialog,
      onOpenAddOptionDialog,
      onClearCellValues,
      shouldCellBePreventedFromEditing
    }),
    [
      columns,
      table.rows,
      components,
      openedChildTableDialog,
      openedLinkedRowDialog,
      openedAddOptionDialog,
      isQuickFilterActive,
      quickFilterText,
      openedCellContextMenu,
      onCloseCellContextMenu,
      onOpenCellContextMenu,
      onColumnResize,
      onColumnMove,
      onCloseChildTableDialog,
      onOpenLinkedRowDialog,
      onCloseLinkedRowDialog,
      getRowStyle,
      setQuickFilterText,
      setIsQuickFilterActive,
      onOpenChildTable,
      onCloseAddOptionDialog,
      onOpenAddOptionDialog,
      onClearCellValues,
      shouldCellBePreventedFromEditing
    ]
  );

  return <GridContext.Provider value={gridState}>{children}</GridContext.Provider>;
};

export const useGrid = (): GridState => {
  const context = useContext(GridContext);
  if (context === undefined) {
    throw new Error('useGrid must be used within a GridProvider');
  }
  return context;
};
