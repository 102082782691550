'use client';

import { PieceOfInformationDto, PieceOfInformationType } from '@company/common/types';
import { Box, Dialog, Flex } from '@company/ui/components';
import { useUpdateQueryParams } from '@company/ui/hooks';
import { PieceOfInformationEditor } from '@components/piece-of-information';
import { downloadFile } from '@lib/file';
import { useSidebar } from '@providers/sidebar-provider';
import { trpc } from '@server/trpc';
import { useRouter, useSearchParams } from 'next/navigation';
import React from 'react';

type DisplayMode = 'panel' | 'dialog' | 'fullscreen' | 'hidden';

interface PieceOfInformationEditorContextProps {
  openedPieceOfInformation: PieceOfInformationDto | null;
  activeChangeProposalId: string | null;
  displayMode: DisplayMode;
  isLoading: (pieceOfInformation: PieceOfInformationDto) => boolean;
  onPieceOfInformationOpen: (args: {
    pieceOfInformation: PieceOfInformationDto;
    displayMode: 'panel' | 'dialog' | 'fullscreen';
    changeProposalId?: string | null;
    viewId?: string | null;
  }) => void;
  onPieceOfInformationClose: () => void;
  onPieceOfInformationMaximize: () => void;
  onPieceOfInformationMinimize: () => void;
}

const PieceOfInformationEditorContext = React.createContext<PieceOfInformationEditorContextProps>({
  openedPieceOfInformation: null,
  activeChangeProposalId: null,
  displayMode: 'hidden',
  isLoading: () => false,
  onPieceOfInformationOpen: () => {},
  onPieceOfInformationClose: () => {},
  onPieceOfInformationMaximize: () => {},
  onPieceOfInformationMinimize: () => {}
});
export const usePieceOfInformationEditor = () => React.useContext(PieceOfInformationEditorContext);

const VIEW_ID_QUERY_PARAM = 'viewId';
const CHANGE_PROPOSAL_ID_QUERY_PARAM = 'changeProposalId';
const DISPLAY_MODE_QUERY_PARAM = 'displayMode';
const PIECE_OF_INFORMATION_ID_QUERY_PARAM = 'pieceOfInformationId';
const DEFAULT_DISPLAY_MODE = 'hidden';

export const PieceOfInformationEditorProvider = ({ children }: { children: React.ReactNode }) => {
  const { expand: expandSidebar, hide: hideSidebar } = useSidebar();
  const router = useRouter();
  const searchParams = useSearchParams();
  const trpcUtils = trpc.useUtils();
  const { addQueryParams, removeQueryParams } = useUpdateQueryParams();

  const [openedPieceOfInformation, setOpenedPieceOfInformation] =
    React.useState<PieceOfInformationDto | null>(null);
  const [displayMode, setDisplayMode] = React.useState<DisplayMode>(DEFAULT_DISPLAY_MODE);
  const [displayModeBeforeMaximize, setDisplayModeBeforeMaximize] =
    React.useState<DisplayMode>(DEFAULT_DISPLAY_MODE);
  const [activeChangeProposalId, setActiveChangeProposalId] = React.useState<string | null>(null);
  const [isLoadingMap, setIsLoadingMap] = React.useState<Record<string, boolean>>({});
  const [isInitialLoading, setIsInitialLoading] = React.useState(
    !!searchParams.get(PIECE_OF_INFORMATION_ID_QUERY_PARAM)
  );

  const onPieceOfInformationOpen = async ({
    pieceOfInformation,
    displayMode,
    changeProposalId,
    viewId
  }: {
    pieceOfInformation: PieceOfInformationDto;
    displayMode: DisplayMode;
    changeProposalId?: string | null;
    viewId?: string | null;
  }) => {
    const typeToOnClick: Record<PieceOfInformationType, () => Promise<void>> = {
      FILE: async () => {
        setIsLoadingMap(prev => ({ ...prev, [pieceOfInformation.id]: true }));
        await downloadFile({ fileId: pieceOfInformation.itemId });
        setIsLoadingMap(prev => ({ ...prev, [pieceOfInformation.id]: false }));
      },
      TABLE: async () => {
        setDisplayMode(displayMode);
        setDisplayModeBeforeMaximize(displayMode);

        if (pieceOfInformation.type === 'TABLE') {
          setOpenedPieceOfInformation({
            ...pieceOfInformation,
            type: 'TABLE',
            table: {
              ...pieceOfInformation.table,
              viewId: viewId ?? pieceOfInformation.table.viewId
            }
          });

          setActiveChangeProposalId(changeProposalId ?? null);
          if (displayMode === 'fullscreen' || displayMode === 'panel') {
            hideSidebar();
          }
        }
      },
      DOCUMENT: async () => {
        setDisplayMode(displayMode);
        setDisplayModeBeforeMaximize(displayMode);

        if (pieceOfInformation.type === 'DOCUMENT') {
          setOpenedPieceOfInformation({
            ...pieceOfInformation,
            type: 'DOCUMENT',
            document: {
              ...pieceOfInformation.document,
              pieceOfInformationId: pieceOfInformation.id
            }
          });
          if (displayMode === 'fullscreen' || displayMode === 'panel') {
            hideSidebar();
          }
        }
      },
      EMAIL_DRAFT: async () => {
        setDisplayMode(displayMode);
        setDisplayModeBeforeMaximize(displayMode);

        if (pieceOfInformation.type === 'EMAIL_DRAFT') {
          setOpenedPieceOfInformation({
            ...pieceOfInformation,
            type: 'EMAIL_DRAFT',
            emailDraft: {
              ...pieceOfInformation.emailDraft,
              pieceOfInformationId: pieceOfInformation.id
            }
          });
          if (displayMode === 'fullscreen' || displayMode === 'panel') {
            hideSidebar();
          }
        }
      },
      PROJECT: async () => {
        router.push(`/projects/${pieceOfInformation.itemId}`);
      },
      CONVERSATION: async () => {
        if (pieceOfInformation.type !== 'CONVERSATION') {
          return;
        }
        const conversationPathSuffix = `/conversations/${pieceOfInformation.conversation.id}`;
        if (pieceOfInformation.conversation.projectId) {
          router.push(
            `/projects/${pieceOfInformation.conversation.projectId}${conversationPathSuffix}`
          );
        } else {
          router.push(conversationPathSuffix);
        }
      }
    };
    typeToOnClick[pieceOfInformation.type]();
  };

  const onPieceOfInformationClose = () => {
    setOpenedPieceOfInformation(null);
    setActiveChangeProposalId(null);
    setDisplayMode('hidden');
    expandSidebar();
  };

  const onPieceOfInformationMaximize = () => {
    setDisplayMode('fullscreen');
  };

  const onPieceOfInformationMinimize = () => {
    if (displayModeBeforeMaximize === 'dialog') {
      setDisplayMode('dialog');
    } else {
      setDisplayMode('panel');
    }
  };

  const isLoading = (pieceOfInformation: PieceOfInformationDto) =>
    isLoadingMap[pieceOfInformation.id] ?? false;

  const value = React.useMemo(
    () => ({
      openedPieceOfInformation,
      activeChangeProposalId,
      displayMode,
      isLoading,
      onPieceOfInformationOpen,
      onPieceOfInformationClose,
      onPieceOfInformationMaximize,
      onPieceOfInformationMinimize
    }),
    [openedPieceOfInformation, displayMode, activeChangeProposalId, isLoading]
  );

  React.useEffect(() => {
    const viewId = searchParams.get(VIEW_ID_QUERY_PARAM);
    const displayMode = searchParams.get(DISPLAY_MODE_QUERY_PARAM) as DisplayMode | undefined;
    const pieceOfInformationId = searchParams.get(PIECE_OF_INFORMATION_ID_QUERY_PARAM);
    const changeProposalId = searchParams.get(CHANGE_PROPOSAL_ID_QUERY_PARAM);

    if (displayMode === 'hidden') {
      onPieceOfInformationClose();
      setIsInitialLoading(false);
      return;
    }

    const isSamePieceOfInformation =
      openedPieceOfInformation &&
      openedPieceOfInformation.id === pieceOfInformationId &&
      openedPieceOfInformation.type === 'TABLE' &&
      viewId === openedPieceOfInformation.table.viewId;

    if (isSamePieceOfInformation) {
      if (displayMode) {
        setDisplayMode(displayMode);
      }
      setIsInitialLoading(false);
      return;
    }

    if (!pieceOfInformationId) {
      setIsInitialLoading(false);
      return;
    }

    const openPieceOfInformation = async () => {
      const pieceOfInformation = await trpcUtils.pieceOfInformation.getById.fetch({
        id: pieceOfInformationId
      });
      onPieceOfInformationOpen({
        pieceOfInformation,
        displayMode: displayMode ?? 'panel',
        changeProposalId,
        viewId
      });
      setIsInitialLoading(false);
    };
    openPieceOfInformation();
  }, [searchParams]);

  React.useEffect(() => {
    if (openedPieceOfInformation) {
      addQueryParams({
        [DISPLAY_MODE_QUERY_PARAM]: displayMode,
        [PIECE_OF_INFORMATION_ID_QUERY_PARAM]: openedPieceOfInformation.id,
        [CHANGE_PROPOSAL_ID_QUERY_PARAM]: activeChangeProposalId ?? undefined,
        [VIEW_ID_QUERY_PARAM]:
          openedPieceOfInformation.type === 'TABLE'
            ? openedPieceOfInformation.table.viewId
            : undefined
      });
    } else {
      if (!isInitialLoading) {
        removeQueryParams([
          DISPLAY_MODE_QUERY_PARAM,
          PIECE_OF_INFORMATION_ID_QUERY_PARAM,
          CHANGE_PROPOSAL_ID_QUERY_PARAM,
          VIEW_ID_QUERY_PARAM
        ]);
      }
    }
  }, [displayMode, openedPieceOfInformation, isInitialLoading]);

  if (isInitialLoading) {
    return null;
  }

  return (
    <PieceOfInformationEditorContext.Provider value={value}>
      <Flex
        h={'full'}
        w={'full'}
        flexGrow={1}
        flexShrink={1}
        flexBasis={0}
        gap={0}
        overflow="hidden"
      >
        <Box
          minW={displayMode === 'panel' ? '480px' : 'full'}
          w={displayMode === 'panel' ? '480px' : 'full'}
          h={'full'}
          display={displayMode === 'fullscreen' ? 'none' : 'block'}
        >
          {children}
        </Box>
        <Box
          borderLeftWidth={displayMode === 'panel' ? 1 : 0}
          w={'full'}
          h={'full'}
          display={displayMode === 'hidden' || displayMode === 'dialog' ? 'none' : 'block'}
        >
          <PieceOfInformationEditor />
        </Box>
      </Flex>
      <Dialog.Root
        open={displayMode === 'dialog'}
        onOpenChange={({ open }) => {
          if (!open) {
            onPieceOfInformationClose();
          }
        }}
        lazyMount
        size={'cover'}
      >
        <Dialog.Backdrop />
        <Dialog.Positioner p={{ base: 0, lg: 8 }}>
          <Dialog.Content>
            <PieceOfInformationEditor />
          </Dialog.Content>
        </Dialog.Positioner>
      </Dialog.Root>
    </PieceOfInformationEditorContext.Provider>
  );
};
